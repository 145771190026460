import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
// Formik
import * as Yup from 'yup';
import { useFormik } from 'formik';
//Import Breadcrumb
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { SetTable } from '../../UsersTable';
import { columns } from './Constants';
import { BASE_URL } from 'helpers';
import { toast, ToastContainer } from 'react-toastify';
// Updated type definition to include all fields
type User = {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  is_employee: boolean;
  national_id: string;
  groups: string[];
};

interface Group {
  id: number;
  name: string;
}

const UsersList = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // Delete customer
  const [modal, setModal] = useState<boolean>(false);
  // State to hold the users data
  const [users, setUsers] = useState<User[]>([]);
  // State to handle loading status
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // State to handle any error
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      // Adjust the endpoint as necessary
      const response = await fetch(`${BASE_URL}/users/users/`);
      if (!response.ok) {
        throw new Error('Something went wrong'); // Handle non-2xx responses
      }
      const data = await response.json();
      setUsers(data); // Assuming the API response has a 'data' field
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []); // Empty dependency array means this effect runs once after the initial render

  const toggle = useCallback(() => {
    setModal((prevModal) => !prevModal);
  }, []);

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      is_employee: false,
      national_id: '',
      groups: [],
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Please enter a username'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email'),
      password: Yup.string().required('Please enter a password'),
      first_name: Yup.string().required('Please enter your first name'),
      last_name: Yup.string().required('Please enter your last name'),
      phone_number: Yup.string().required('Please enter your phone number'),
      is_employee: Yup.boolean().required('Please Enter Your Status'),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      saveNewUser({ ...values, id: undefined })
        .then((user: any) => {
          toast.success('User saved successfully', user);
          resetForm();
          toggle();
          fetchUsers();
        })
        .catch((error: any) => {
          toast.error('Failed to save user', error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  // Adjust the type definition for the argument to `saveNewUser` to reflect
  // that `groups` is now an array of strings.
  async function saveNewUser(userData: {
    id: undefined;
    username: string;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    is_employee: boolean;
    national_id: string;
    groups: string[]; // Corrected to an array of strings
  }) {
    try {
      const response = await fetch(`${BASE_URL}/users/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...userData,
          groups: userData.groups.map(Number), // Assuming you need to convert group IDs from strings to numbers if your backend expects numerical IDs
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to save user');
      }

      const data = await response.json();
      toast.success('User saved successfully');
      return data;
    } catch (error: unknown) {
      let errorMessage = 'An error occurred while saving the user';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast.error(errorMessage);
      throw error;
    }
  }

  const [groups, setGroups] = useState<Group[]>([]);

  useEffect(() => {
    fetch('https://django.athomecaptial.co.ke/users/groups/')
      .then((response) => response.json())
      .then((data) => setGroups(data))
      .catch((error) => console.error('Error fetching groups:', error));
  }, []);

  document.title = 'Users | At Home Capital || Credit Management System';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Users" pageTitle="User Management" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">User List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn me-1"
                          id="create-btn"
                          onClick={() => {
                            setIsEdit(false);
                            toggle();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          User
                        </button>{' '}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <SetTable
                      columns={columns(fetchUsers)}
                      dataSource={users}
                      loading={isLoading}
                    />
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {'Add User'}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        {/* Username */}
                        <div className="mb-3">
                          <Label
                            htmlFor="username-field"
                            className="form-label"
                          >
                            Username
                          </Label>
                          <Input
                            name="username"
                            id="username-field"
                            className="form-control"
                            placeholder="Enter Username"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username}
                            invalid={
                              validation.touched.username &&
                              !!validation.errors.username
                            }
                          />
                          {validation.touched.username &&
                            validation.errors.username && (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            )}
                        </div>

                        {/* Email */}
                        <div className="mb-3">
                          <Label htmlFor="email-field" className="form-label">
                            Email
                          </Label>
                          <Input
                            name="email"
                            type="email"
                            id="email-field"
                            placeholder="Enter Email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email}
                            invalid={
                              validation.touched.email &&
                              !!validation.errors.email
                            }
                          />
                          {validation.touched.email &&
                            validation.errors.email && (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            )}
                        </div>

                        {/* First Name */}
                        <div className="mb-3">
                          <Label
                            htmlFor="first-name-field"
                            className="form-label"
                          >
                            First Name
                          </Label>
                          <Input
                            name="first_name"
                            id="first-name-field"
                            className="form-control"
                            placeholder="Enter First Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.first_name}
                            invalid={
                              validation.touched.first_name &&
                              !!validation.errors.first_name
                            }
                          />
                          {validation.touched.first_name &&
                            validation.errors.first_name && (
                              <FormFeedback type="invalid">
                                {validation.errors.first_name}
                              </FormFeedback>
                            )}
                        </div>

                        {/* Last Name */}
                        <div className="mb-3">
                          <Label
                            htmlFor="last-name-field"
                            className="form-label"
                          >
                            Last Name
                          </Label>
                          <Input
                            name="last_name"
                            id="last-name-field"
                            className="form-control"
                            placeholder="Enter Last Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.last_name}
                            invalid={
                              validation.touched.last_name &&
                              !!validation.errors.last_name
                            }
                          />
                          {validation.touched.last_name &&
                            validation.errors.last_name && (
                              <FormFeedback type="invalid">
                                {validation.errors.last_name}
                              </FormFeedback>
                            )}
                        </div>

                        {/* Password */}
                        <div className="mb-3">
                          <Label
                            htmlFor="password-field"
                            className="form-label"
                          >
                            Password
                          </Label>
                          <Input
                            name="password"
                            type="password"
                            id="password-field"
                            className="form-control"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password}
                            invalid={
                              validation.touched.password &&
                              !!validation.errors.password
                            }
                          />
                          {validation.touched.password &&
                            validation.errors.password && (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            )}
                        </div>

                        {/* Phone Number */}
                        <div className="mb-3">
                          <Label
                            htmlFor="phone-number-field"
                            className="form-label"
                          >
                            Phone Number
                          </Label>
                          <Input
                            name="phone_number"
                            type="text"
                            id="phone-number-field"
                            className="form-control"
                            placeholder="Enter Phone Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone_number}
                            invalid={
                              validation.touched.phone_number &&
                              !!validation.errors.phone_number
                            }
                          />
                          {validation.touched.phone_number &&
                            validation.errors.phone_number && (
                              <FormFeedback type="invalid">
                                {validation.errors.phone_number}
                              </FormFeedback>
                            )}
                        </div>

                        {/* Is Employee */}
                        <div className="mb-3">
                          <Label
                            htmlFor="is-employee-field"
                            className="form-label"
                          >
                            Is Employee
                          </Label>
                          {` `}
                          <Input
                            name="is_employee"
                            type="checkbox"
                            id="is-employee-field"
                            className="form-check-input"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            checked={validation.values.is_employee}
                          />
                          {/* Typically, validation for checkboxes (boolean fields) might not require visual feedback like 'invalid' message, but you can add it if your logic needs it. */}
                        </div>

                        {/* National ID */}
                        <div className="mb-3">
                          <Label
                            htmlFor="national-id-field"
                            className="form-label"
                          >
                            National ID
                          </Label>
                          <Input
                            name="national_id"
                            type="text"
                            id="national-id-field"
                            className="form-control"
                            placeholder="Enter National ID"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.national_id}
                            invalid={
                              validation.touched.national_id &&
                              !!validation.errors.national_id
                            }
                          />
                          {validation.touched.national_id &&
                            validation.errors.national_id && (
                              <FormFeedback type="invalid">
                                {validation.errors.national_id}
                              </FormFeedback>
                            )}
                        </div>
                        <div className="mb-3">
                          <Label for="groupSelect">Group</Label>
                          <Input
                            type="select"
                            name="groups"
                            id="groupSelect"
                            value={validation.values.groups} // Make sure this reflects Formik's state
                            onChange={(event) => {
                              // Convert selected group ID from string to actual value if necessary
                              const value = event.target.value;
                              validation.setFieldValue('groups', [value]); // Update this line as needed to support multiple selections
                            }}
                          >
                            <option value="">Select a group</option>
                            {groups.map((group) => (
                              <option
                                key={group.id}
                                value={group.id.toString()}
                              >
                                {group.name}
                              </option>
                            ))}
                          </Input>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              toggle();
                            }}
                          >
                            Close
                          </button>

                          <button type="submit" className="btn btn-success">
                            {!!isEdit ? 'Update User' : 'Add User'}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsersList;
