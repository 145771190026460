import React, { useEffect } from 'react';
import { Navigate, useNavigate, Route } from 'react-router-dom';
import { setAuthorization } from '../helpers/api_helper';
import { useDispatch } from 'react-redux';
import { useProfile } from '../Components/Hooks/UserHooks';

// Define interfaces for any props or states if necessary
interface AuthProtectedProps {
  children: React.ReactNode;
}

// Updated to use for v6 routing
interface AccessRouteProps {
  path: string;
  element: React.ReactElement;
}

// Define the types for your props or imported functions as needed
// interface Props {
//   token: string | null;
//   setAuthorization: (token: string) => void;
//   logout: () => void;
//   dispatch: React.Dispatch<any>; // Adjust according to your actual dispatch type
//   navigate: (path: string) => void; // Adjust according to your actual navigate function signature
// }

// AuthProtected component provides route protection
const AuthProtected: React.FC<AuthProtectedProps> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const { loading } = useProfile();

  // Function to log out the user
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logout = () => {
    // Clear user session data, including the token
    sessionStorage.clear();
    localStorage.clear();
    // Redirect the user to the login page (you can replace '/login' with your actual login page URL)
    navigate('/login');
  };

  useEffect(() => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('token');

    if (token) {
      setAuthorization(token);
    }
  }, []);

  useEffect(() => {
    const handleTokenExpiration = () => {
      const expirationDate = sessionStorage.getItem('accessExpires');
      if (expirationDate) {
        try {
          const expirationDateObj = new Date(expirationDate);
          const currentDate = new Date();
          const timeUntilExpiration =
            expirationDateObj.getTime() - currentDate.getTime();
          if (timeUntilExpiration <= 0) {
            console.log('Token has expired');
            logout();
          } else {
            // Optionally, check if the token needs refreshing before it expires
            setAuthorization(token as string); // Asserting that token is not null
            console.log('User is logged in. Token:', token);
          }
        } catch (error) {
          console.error('Error handling token expiration:', error);
          logout(); // Consider logging out or handling the error differently
        }
      } else {
        console.log('No expiration date found, logging out');
        logout();
      }
    };

    if (token) {
      handleTokenExpiration();
    } else {
      logout();
    }
  }, [token, dispatch, navigate, logout]);

  if (loading && !token) {
    return <Navigate to="/login" replace state={{ from: 'currentLocation' }} />;
  }

  return <>{children}</>;
};

// Example of how to use AccessRoute with React Router v6
const AccessRoute: React.FC<AccessRouteProps> = ({ element, path }) => {
  return <Route path={path} element={element} />;
};

export { AuthProtected, AccessRoute };
