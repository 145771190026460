import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from 'reactstrap';
import { BASE_URL } from 'helpers';
import { Avatar } from 'antd';

interface UserProfileProps {
  username: string;
  avatarUrl?: string | null;
}

const UserProfile: React.FC<UserProfileProps> = ({ username, avatarUrl }) => {
  const hasAvatar = !!avatarUrl;

  const getInitials = (name: string): string => {
    return name
      .split(' ')
      .map((n) => n[0])
      .join('')
      .toUpperCase();
  };

  return (
    <Avatar src={hasAvatar ? avatarUrl : undefined} size={64}>
      {hasAvatar ? null : getInitials(username)}
    </Avatar>
  );
};

const ProfileDropdown = () => {
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userName, setUserName] = useState('');
  const [userGroup, setUserGroup] = useState('');
  const [modalList, setModalList] = useState(false);

  const logoutUser = () => {
    sessionStorage.clear();
    localStorage.removeItem('token');
    window.location.href = process.env.PUBLIC_URL + '/login';
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch(`${BASE_URL}/users/get-user-from-token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "token": token }),
      })
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) { // Unauthorized
            throw new Error('Authentication failed');
          } else {
            throw new Error('Network or server error');
          }
        }
        return response.json();
      })
      .then(data => {
        setUserData(data);
        setUserName(data.username);
        setUserGroup(data.group_details?.[0]?.name || '');
        sessionStorage.setItem('userData', JSON.stringify(data));
        sessionStorage.setItem('userName', data.username);
        sessionStorage.setItem('userGroup', data.group_details?.[0]?.name || '');
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        if (error.message === 'Authentication failed') {
          logoutUser();
        }
      });
    } else {
      console.error('Token not found, user is not logged in.');
      logoutUser();
    }
  }, []);
  
  
  const toggleProfileDropdown = () => setIsProfileDropdown(!isProfileDropdown);

  const toggleList = () => setModalList(!modalList);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center">
            <UserProfile username={userName} avatarUrl="" />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userName}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {userGroup}
              </span>
            </span>
          </span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {userName}!</h6>
          <DropdownItem
            href={process.env.PUBLIC_URL + '/pages-profile-settings'}
          >
            <span className="badge bg-success-subtle text-success mt-1 float-end">
              New
            </span>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Settings</span>
          </DropdownItem>

          <DropdownItem
            color="success"
            onClick={() => toggleList()}
            id="create-btn"
          >
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Logout</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <Modal isOpen={modalList} toggle={toggleList} centered>
        <ModalHeader className="bg-light p-3" toggle={toggleList}>
          Confirm Logout
        </ModalHeader>
        <ModalBody>
          <div className="text-center mt-2">
            <h5 className="text-primary">ARE YOU SURE YOU WANT TO LOGOUT?</h5>
            <Button color="success" className="w-100 mt-4" onClick={logoutUser}>
              Logout
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ProfileDropdown;
