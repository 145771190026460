// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'reactstrap';

// Import components
import AuthSlider from '../authCarousel';

const EnterOTP = () => {
  const [otp, setOtp] = useState(new Array(6).fill('')); // Create an array state variable for the 6 inputs
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const [timeRemaining, setTimeRemaining] = useState(120); // 2 minutes = 120 seconds

  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      // Handle OTP expiration, like showing a message or redirecting the user
      console.log('OTP expired');
    }
  }, [timeRemaining]);

  // Your handleChange, moveToNext, handleSubmit, and other functions

  const formattedTime = `${Math.floor(timeRemaining / 60)}:${String(
    timeRemaining % 60
  ).padStart(2, '0')}`;

  // This effect runs once when the component mounts and focuses the first input field
  useEffect(() => {
    document.getElementById('otp-input-0').focus();
  }, []);
  // and handle deletion of characters
  const moveToNext = (index, event) => {
    const inputElement = document.getElementById(`otp-input-${index}`);

    if (event.key === 'Backspace' && inputElement.value === '' && index > 0) {
      // If backspace is pressed and the current input is empty, move focus to the previous input
      document.getElementById(`otp-input-${index - 1}`).focus();
    } else if (inputElement.value.length === 1 && index < 5) {
      // Move focus to next input if not the last input
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  useEffect(() => {
    // Adding event listeners to each input element for 'keydown' event
    for (let i = 0; i < 6; i++) {
      // assuming 6 input fields
      const inputElement = document.getElementById(`otp-input-${i}`);
      inputElement &&
        inputElement.addEventListener('keydown', (event) =>
          moveToNext(i, event)
        );
    }

    // Cleanup function to remove event listeners
    return () => {
      for (let i = 0; i < 6; i++) {
        const inputElement = document.getElementById(`otp-input-${i}`);
        inputElement &&
          inputElement.removeEventListener('keydown', (event) =>
            moveToNext(i, event)
          );
      }
    };
  }, []);

  const email = sessionStorage.getItem('email');
  const phoneNumber = sessionStorage.getItem('phoneNumber');

  const maskEmail = (email: string): string => {
    const [localPart, domain] = email.split('@');
    return `${localPart.slice(0, 2)}***@${domain
      .split('.')[0]
      .slice(0, 2)}***.${domain.split('.')[1]}`;
  };

  const maskPhoneNumber = (phoneNumber: string): string => {
    return `${phoneNumber.slice(0, 2)}****${phoneNumber.slice(-2)}`;
  };

  const hintedEmail = maskEmail(email);
  const hintedPhoneNumber = maskPhoneNumber(phoneNumber);

  // Function to update the otp state array based on input
  const handleOtpChange = (index, event) => {
    const newOtp = [...otp];
    newOtp[index] = event.target.value;
    setOtp(newOtp);

    if (event.target.value && index < 5) {
      // Move to next input field if current input is filled
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  // Use useEffect to check if all OTP fields are filled
  useEffect(() => {
    if (otp.length === 6 && otp.every((val) => val.length === 1)) {
      handleSubmit({ preventDefault: () => {} }); // Simulate an event object
    }
  }, [otp]); // Dependency array ensures this runs only when otp state changes

  // Handle form submission for OTP verification
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the form from actually submitting

    // Construct the inputOtp from the state
    const inputOtp = otp.join('');
    console.log(inputOtp);
    // Retrieve the OTP and token from sessionStorage
    const storedOtp = sessionStorage.getItem('otp');
    const token = sessionStorage.getItem('accessToken'); // Assuming you've stored the token as 'token'

    // Compare the input OTP with the stored OTP
    if (inputOtp === storedOtp) {
      // OTPs match, move the token to localStorage or another more persistent storage
      if (token) {
        localStorage.setItem('token', token);

        // Optionally, clear the OTP from sessionStorage after successful verification
        sessionStorage.removeItem('otp');

        // Proceed with application flow, e.g., navigate to the dashboard
        navigate('/dashboard'); // Make sure you have access to navigate or use a suitable method to change routes

        // You might want to show a success message to the user
        console.log(
          'OTP verified successfully, and token moved to application storage.'
        );
      } else {
        // Token not found in sessionStorage, handle accordingly
        console.error('Token not found. Please log in again.');
        setErrorMessage('Token not found. Please log in again.'); // Update state to show error message to user
      }
    } else {
      // OTPs do not match, handle the verification failure
      console.error('OTP verification failed. Please try again.');
      setErrorMessage('OTP verification failed. Please try again.'); // Update state to show error message to user
    }
  };

  // Set the document title (optional)
  document.title = 'OTP | APRPMS';

  // Component JSX
  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden">
                  <Row className="justify-content-center g-0">
                    <AuthSlider />
                    <Col lg={6}>
                      <div className="p-lg-5 p-4">
                        <div>
                          <h5 className="text-primary">OTP Verification</h5>
                          <p className="text-muted">
                            Enter the OTP sent to your {hintedPhoneNumber} or{' '}
                            {hintedEmail} to unlock the screen!
                          </p>
                        </div>
                        <div className="user-thumb text-center">
                          <img
                            src="https://thumbs.dreamstime.com/b/otp-one-time-password-step-authentication-data-protection-internet-security-concept-otp-one-time-password-step-authentication-data-254434939.jpg"
                            className="rounded-circle img-thumbnail avatar-lg"
                            alt="thumbnail"
                          />
                        </div>
                        {errorMessage && (
                          <div className="mt-3 alert alert-danger">
                            {errorMessage}
                          </div>
                        )}
                        {timeRemaining === 0 ? (
                          <div className="text-danger">OTP has expired.</div>
                        ) : (
                          <div
                            style={{
                              color: timeRemaining <= 30 ? 'red' : 'black',
                            }}
                          >
                            OTP Expires in: {formattedTime}
                          </div>
                        )}
                        <div className="mt-4">
                          <form onSubmit={handleSubmit}>
                            <Row>
                              {Array.from({ length: 6 }).map((_, index) => (
                                <Col key={index}>
                                  <input
                                    className="form-control text-center"
                                    key={index}
                                    type="text"
                                    id={`otp-input-${index}`}
                                    maxLength="1"
                                    onChange={(event) =>
                                      handleOtpChange(index, event)
                                    }
                                    style={{
                                      width: '40px',
                                      marginRight: '10px',
                                    }}
                                  />
                                </Col>
                              ))}
                            </Row>
                            <div className="mb-2 mt-4">
                              <Button
                                color="success"
                                className="w-100"
                                type="submit"
                              >
                                Unlock
                              </Button>
                            </div>
                          </form>
                        </div>
                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            Not you? Return{' '}
                            <Link
                              to="/login"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              Signin
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EnterOTP;
