import React from 'react';
import ReactApexChart from 'react-apexcharts';
import getChartColorsArray from '../../../Components/Common/ChartsDynamicColor';

const SalesForecastCharts = ({ dataColors, series }: any) => {
  var areachartSalesColors = getChartColorsArray(dataColors);

  var options: any = {
    chart: {
      type: 'bar',
      height: 341,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '65%',
      },
    },
    stroke: {
      show: true,
      width: 5,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [''],
      axisTicks: {
        show: false,
        borderType: 'solid',
        color: '#78909C',
        height: 6,
        offsetX: 0,
        offsetY: 0,
      },
      title: {
        text: 'Total Forecasted Value',
        offsetX: 0,
        offsetY: -30,
        style: {
          color: '#78909C',
          fontSize: '12px',
          fontWeight: 400,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value: any) {
          return '$' + value + 'k';
        },
      },
      tickAmount: 4,
      min: 0,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      fontWeight: 500,
      offsetX: 0,
      offsetY: -14,
      itemMargin: {
        horizontal: 8,
        vertical: 0,
      },
      markers: {
        width: 10,
        height: 10,
      },
    },
    colors: areachartSalesColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        dir="ltr"
        options={options}
        series={series}
        type="bar"
        height="341"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export { SalesForecastCharts };
