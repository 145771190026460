import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner, // Import Spinner for visual feedback
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { BASE_URL } from 'helpers';

// Define the structure of your formData and loading states
interface SmsConfigData {
  id: string;
  api_key: string;
  partner_id: string;
  shortcode: string;
  sender_id: string;
}

interface LoadingState {
  id: boolean;
  api_key: boolean;
  partner_id: boolean;
  shortcode: boolean;
  sender_id: boolean;
}

const SmsConfig = () => {
  const [formData, setFormData] = useState<SmsConfigData>({
    id: '',
    api_key: '',
    partner_id: '',
    shortcode: '',
    sender_id: '',
  });
  const [loading, setLoading] = useState<LoadingState>({
    id: true,
    api_key: true,
    partner_id: true,
    shortcode: true,
    sender_id: true,
  });
  const [smsConfigId, setSmsConfigId] = useState<string | null>(null);

  useEffect(() => {
    fetch(`${BASE_URL}/sms/sms-config/`)
      .then((res) => res.json())
      .then((data) => {
        const configData = data[0];
        setSmsConfigId(configData.id);
        setFormData({
          id: configData.id,
          api_key: configData.api_key,
          partner_id: configData.partner_id,
          shortcode: configData.shortcode,
          sender_id: configData.sender_id,
        });
        setLoading({
          id: false,
          api_key: false,
          partner_id: false,
          shortcode: false,
          sender_id: false,
        });
      });
  }, []);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (smsConfigId) {
      fetch(`${BASE_URL}/sms/sms-config/${smsConfigId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then(() => {
          alert('SMS Config updated successfully!');
        })
        .catch((error) => {
          console.error('Error updating SMS Config:', error);
        });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // Simplified for brevity - extend as needed
  const renderInputOrSpinner = (key: keyof SmsConfigData) => {
    return loading[key] ? (
      <Spinner size="sm" /> // Placeholder for loading state
    ) : (
      <Input
        type="text"
        name={key}
        id={`${key}Input`}
        placeholder={`Enter ${key.replace('_', ' ')}`}
        value={formData[key]}
        onChange={handleChange}
        disabled={key === 'id'}
      />
    );
  };

  document.title = 'SMS Config | APRPMS';

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="SMS Configuration" pageTitle="Bulk SMS" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">SMS Configuration</h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <form onSubmit={handleSubmit}>
                    {Object.keys(formData).map((key) => (
                      <Row key={key}>
                        <Col md={12}>
                          <div className="mb-3">
                            <Label
                              for={`${key}Input`}
                              className="form-label text-uppercase fw-semibold"
                            >
                              {key.replace('_', ' ')}
                            </Label>
                            {renderInputOrSpinner(key as keyof SmsConfigData)}
                          </div>
                        </Col>
                      </Row>
                    ))}
                    <div className="text-end">
                      <button type="submit" className="btn btn-primary">
                        EDIT
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SmsConfig;
