import { BASE_URL } from 'helpers';

// New interface for ClientCount
export interface ClientCount {
  client_count: number;
}

export async function fetchClientCount(): Promise<ClientCount> {
  try {
    const response = await fetch(`${BASE_URL}/clients/api/client-count/`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const jsonResponse = await response.json();
    if (jsonResponse.status !== 'success') {
      throw new Error('Failed to fetch client count: ' + jsonResponse.message);
    }
    return jsonResponse.data;
  } catch (error) {
    throw error;
  }
}
