import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { BASE_URL } from 'helpers';

interface MpesaConfigData {
  id: number;
  consumer_key: string;
  consumer_secret: string;
  initiator_name: string;
  security_credential: string;
  short_code: string;
  queue_timeout_url: string;
  result_url: string;
  is_active: boolean;
}

interface LoadingState {
  consumer_key: boolean;
  consumer_secret: boolean;
  initiator_name: boolean;
  security_credential: boolean;
  short_code: boolean;
  queue_timeout_url: boolean;
  result_url: boolean;
  is_active: boolean;
}

const MpesaConfig = () => {
  const [formData, setFormData] = useState<MpesaConfigData>({
    id: 0,
    consumer_key: '',
    consumer_secret: '',
    initiator_name: '',
    security_credential: '',
    short_code: '',
    queue_timeout_url: '',
    result_url: '',
    is_active: true,
  });
  const [loading, setLoading] = useState<LoadingState>({
    consumer_key: false, // Start with false assuming the data is not yet loading
    consumer_secret: false,
    initiator_name: false,
    security_credential: false,
    short_code: false,
    queue_timeout_url: false,
    result_url: false,
    is_active: false,
  });
  const [mpesaConfigId, setMpesaConfigId] = useState<number | null>(null);

  useEffect(() => {
    // Simulate loading state before fetching data
    setLoading((prevState) => ({
      ...prevState,
      consumer_key: true,
      consumer_secret: true,
      initiator_name: true,
      security_credential: true,
      short_code: true,
      queue_timeout_url: true,
      result_url: true,
      is_active: true,
    }));

    fetch(`${BASE_URL}/disbursements/mpesa_configs/`)
      .then((res) => res.json())
      .then((data) => {
        const configData = data[0]; // Assuming the first configuration is the one we need
        setMpesaConfigId(configData.id);
        setFormData({
          ...configData,
        });
        // Correctly set loading to false for all after fetch
        setLoading({
          consumer_key: false,
          consumer_secret: false,
          initiator_name: false,
          security_credential: false,
          short_code: false,
          queue_timeout_url: false,
          result_url: false,
          is_active: false,
        });
      })
      .catch((error) => {
        console.error('Error fetching M-Pesa Config:', error);
        // Optionally set loading to false here if error to stop spinner
      });
  }, []);

  // Adjusted renderInputOrSpinner logic
  const renderInputOrSpinner = (key: keyof MpesaConfigData) => {
    // Directly return spinner if loading
    if (loading[key as keyof LoadingState]) {
      return <Spinner size="sm" />;
    }

    // Handle the is_active checkbox specifically
    if (key === 'is_active') {
      return (
        <Input
          type="checkbox"
          name={key}
          id={`${key}Input`}
          checked={formData[key]}
          onChange={handleChange}
        />
      );
    }

    // Handle all other inputs
    return (
      <Input
        type="text"
        name={key}
        id={`${key}Input`}
        placeholder={`Enter ${key.replace('_', ' ')}`}
        value={formData[key]}
        onChange={handleChange}
      />
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const isCheckbox = e.target.type === 'checkbox';
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: isCheckbox ? e.target.checked : value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (mpesaConfigId) {
      fetch(`${BASE_URL}/disbursements/mpesa_configs/${mpesaConfigId}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then(() => {
          alert('M-Pesa Config updated successfully!');
        })
        .catch((error) => {
          console.error('Error updating M-Pesa Config:', error);
        });
    }
  };

  document.title = 'M-Pesa Config | APRPMS';

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="M-Pesa Configuration" pageTitle="Disbursements" />
          <Row>
            <Col lg={12}>
              <Card id="configList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <h5 className="card-title mb-0">M-Pesa Configuration</h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <form onSubmit={handleSubmit}>
                    {Object.keys(formData).map((key) =>
                      key !== 'id' ? ( // Exclude 'id' from the form fields
                        <Row key={key}>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label
                                for={`${key}Input`}
                                className="form-label text-uppercase fw-semibold"
                              >
                                {key.replace('_', ' ')}
                              </Label>
                              {renderInputOrSpinner(
                                key as keyof MpesaConfigData
                              )}
                            </div>
                          </Col>
                        </Row>
                      ) : null
                    )}
                    <div className="text-end">
                      <button type="submit" className="btn btn-primary">
                        EDIT
                      </button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MpesaConfig;
