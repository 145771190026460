import React from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { ecomWidgets } from '../../common/data';
import DisbursementsStats from './widges/DisbursementsStats';
import ClientStats from './widges/ClientStats';

const Widgets = () => {
  return (
    <React.Fragment>
      <DisbursementsStats />
      <ClientStats />
    </React.Fragment>
  );
};

export default Widgets;
