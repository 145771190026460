import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
// Formik
import * as Yup from 'yup';
import { useFormik } from 'formik';
//Import Breadcrumb
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { SetTable } from '../UsersTable';
import { columns } from './Constants';
import { BASE_URL } from 'helpers';
import { toast, ToastContainer } from 'react-toastify';
import { Table } from 'antd';
// Updated type definition to include all fields
import { Key } from 'antd/es/table/interface';
type Permission = {
  id: number;
  name: string;
  codename: string;
};

type Group = {
  name: string;
  permissions: Permission[];
};

type GroupDetails = {
  name: string;
  permissions_details: Permission[];
};

const permissionsColumns = [
  { title: 'Permission Name', dataIndex: 'name', key: 'name' },
  { title: 'Codename', dataIndex: 'codename', key: 'codename' },
];

const GroupsList = () => {
  // Delete customer
  const [modal, setModal] = useState<boolean>(false);
  // State to hold the groups data
  const [groups, setGroups] = useState<Group[]>([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  // State to handle loading status
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchPermissions();
  }, []);

  const fetchPermissions = async () => {
    try {
      const response = await fetch(`${BASE_URL}/users/permissions/`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPermissions(data);
    } catch (error) {
      console.error('Failed to fetch permissions:', error);
    }
  };
  // State to handle any error
  const fetchGroups = async () => {
    setIsLoading(true);
    try {
      // Adjust the endpoint as necessary
      const response = await fetch(`${BASE_URL}/users/groups/`);
      if (!response.ok) {
        throw new Error('Something went wrong'); // Handle non-2xx responses
      }
      const data = await response.json();
      setGroups(data); // Assuming the API response has a 'data' field
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const onRowSelectionChange = (
    selectedRowKeys: Key[],
    selectedRows: any[]
  ) => {
    // Assuming all your keys are numbers, but you should add checks if that's not guaranteed
    const numericKeys = selectedRowKeys.map((key) => Number(key));
    setSelectedRowKeys(numericKeys);
    validation.setFieldValue('permissions', numericKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onRowSelectionChange,
    type: 'checkbox',
  };
  useEffect(() => {
    fetchGroups();
  }, []); // Empty dependency array means this effect runs once after the initial render

  const toggle = useCallback(() => {
    setModal((prevModal) => !prevModal);
  }, []);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      permissions: [], // Assuming permissions are selected by ID
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Please enter a group name'),
      permissions: Yup.array()
        .of(Yup.number())
        .min(1, 'Please select at least one permission'), // Validate that at least one permission is selected
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      saveNewGroup(values) // Updated to pass the entire values object
        .then((group) => {
          toast.success('Group saved successfully');
          resetForm();
        })
        .catch((error: any) => {
          toast.error('Failed to save group');
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  async function saveNewGroup(groupData: Group) {
    try {
      // Ensure groupData.permissions contains an array of selected permission IDs
      const response = await fetch(`${BASE_URL}/users/groups/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(groupData),
      });

      if (!response.ok) {
        throw new Error('Failed to save group');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      let errorMessage = 'An error occurred while saving the group';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast.error(errorMessage);
      throw error;
    } finally {
      fetchGroups(); // Refresh groups list after adding a new group
    }
  }

  // Function to render the nested permissions table
  const expandedRowRender = (record: GroupDetails) => {
    const permissionsColumns = [
      { title: 'Permission Name', dataIndex: 'name', key: 'name' },
      { title: 'Codename', dataIndex: 'codename', key: 'codename' },
    ];
    return (
      <Table
        columns={permissionsColumns}
        dataSource={record.permissions_details}
        pagination={false}
      />
    );
  };

  // Correct structure for the expandable prop
  const expandableConfig = {
    expandedRowRender, // Assign your function here
  };

  document.title = 'Groups | At Home Capital || Credit Management System';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Groups" pageTitle="User Management" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Groups</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn me-1"
                          id="create-btn"
                          onClick={() => {
                            toggle();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Group
                        </button>{' '}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <SetTable
                      columns={columns(fetchGroups)}
                      dataSource={groups}
                      loading={isLoading}
                      expandable={expandableConfig}
                    />
                  </div>

                  <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {'Add Group'}
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <div className="mb-3">
                          <Label for="groupName">Group Name</Label>
                          <Input
                            id="groupName"
                            name="name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name}
                            invalid={
                              validation.touched.name &&
                              !!validation.errors.name
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback>
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label>Permissions</Label>
                          <Table
                            rowSelection={{
                              ...rowSelection, // Spreads selectedRowKeys and onChange
                              type: 'checkbox', // Ensure this is not duplicated in ...rowSelection
                            }}
                            columns={permissionsColumns}
                            dataSource={permissions}
                            rowKey="id"
                          />
                          {validation.touched.permissions &&
                          validation.errors.permissions ? (
                            <div style={{ color: 'red', marginTop: '.5rem' }}>
                              {validation.errors.permissions}
                            </div>
                          ) : null}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              toggle();
                            }}
                          >
                            Close
                          </button>

                          <button type="submit" className="btn btn-success">
                            {'Add Group'}
                          </button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GroupsList;
