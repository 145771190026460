import React from 'react';
import { Navigate } from 'react-router-dom';

//Dashboard
import DashboardEcommerce from '../views/Dashboard';

//Calendar
// Email box
import MailInbox from '../pages/EmailInbox';
import BasicAction from '../pages/Email/EmailTemplates/BasicAction';
import EcommerceAction from '../pages/Email/EmailTemplates/EcommerceAction';

//Chat
import Chat from '../pages/Chat';
import Calendar from '../pages/Calendar';

// Project
import ProjectList from '../pages/Projects/ProjectList';
import ProjectOverview from '../pages/Projects/ProjectOverview';
import CreateProject from '../pages/Projects/CreateProject';

//Task
import TaskDetails from '../pages/Tasks/TaskDetails';
import TaskList from '../pages/Tasks/TaskList';

//Invoices
import InvoiceList from '../views/Invoices/InvoiceList';
import InvoiceCreate from '../views/Invoices/InvoiceCreate';
import InvoiceDetails from '../views/Invoices/InvoiceDetails';

// Support Tickets
import ListView from '../pages/SupportTickets/ListView';
import TicketsDetails from '../pages/SupportTickets/TicketsDetails';

// Widgets
import Widgets from '../pages/Widgets/Index';

//Tables
import BasicTables from '../pages/Tables/BasicTables/BasicTables';
import ReactTable from '../pages/Tables/ReactTables';

//Maps
import GoogleMaps from '../pages/Maps/GoogleMaps/GoogleMaps';

//AuthenticationInner pages
import BasicSignIn from '../pages/AuthenticationInner/Login/BasicSignIn';
import CoverSignIn from '../pages/AuthenticationInner/Login/CoverSignIn';
import BasicSignUp from '../pages/AuthenticationInner/Register/BasicSignUp';
import CoverSignUp from '../pages/AuthenticationInner/Register/CoverSignUp';
import BasicPasswReset from '../pages/AuthenticationInner/PasswordReset/BasicPasswReset';
//pages
import Starter from '../views/Starter/Starter';
import SimplePage from '../pages/Pages/Profile/SimplePage/SimplePage';
import Settings from '../pages/Pages/Profile/Settings/Settings';
import Team from '../pages/Pages/Team/Team';
import Timeline from '../pages/Pages/Timeline/Timeline';
import Faqs from '../pages/Pages/Faqs/Faqs';
import Pricing from '../pages/Pages/Pricing/Pricing';
import Gallery from '../pages/Pages/Gallery/Gallery';
import Maintenance from '../pages/Pages/Maintenance/Maintenance';
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import SiteMap from '../pages/Pages/SiteMap/SiteMap';
import SearchResults from '../pages/Pages/SearchResults/SearchResults';

import CoverPasswReset from '../pages/AuthenticationInner/PasswordReset/CoverPasswReset';
import BasicLockScreen from '../pages/AuthenticationInner/LockScreen/BasicLockScr';
import CoverLockScreen from '../pages/AuthenticationInner/LockScreen/CoverLockScr';
import BasicLogout from '../pages/AuthenticationInner/Logout/BasicLogout';
import CoverLogout from '../pages/AuthenticationInner/Logout/CoverLogout';
import BasicSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg';
import CoverSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg';
import BasicTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify';
import CoverTwosVerify from '../views/Authentication/CoverTwosVerify';
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404';
import Error500 from '../pages/AuthenticationInner/Errors/Error500';

import BasicPasswCreate from '../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate';
import CoverPasswCreate from '../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate';
import Offlinepage from '../pages/AuthenticationInner/Errors/Offlinepage';

//login
import Login from '../views/Authentication/Login';
import ForgetPasswordPage from '../views/Authentication/ForgetPassword';
import Logout from '../views/Authentication/Logout';
import Register from '../views/Authentication/Register';

// User Profile
import UserProfile from '../views/Authentication/user-profile';

import FileManager from '../pages/FileManager';
import ToDoList from '../pages/ToDo';
import APIKey from '../pages/APIKey';
import PrivacyPolicy from '../pages/Pages/PrivacyPolicy';
import TermsCondition from '../pages/Pages/TermsCondition';
import Kanbanboard from 'pages/Tasks/KanbanBoard';

import { Releases, Payments } from 'views/disbursements';
import { ClientsList, UsersList } from 'views/users';
import Permissions from 'views/users/permissions/Permissions';
import { GroupsList } from 'views/users/groups';
import { RegistrationList } from 'views/users/clients/registration';
import { SmsConfig } from 'views/bulkSMS/smsConfig';
import { MpesaConfig } from 'views/Mpesa/MPESAConfig';
import { AccessTokens } from 'views/Mpesa/AccessKey';
import EnterOTP from 'views/Authentication/otp/EnterOTP';
import FailedTransactions from 'views/Mpesa/FailedTransactions/FailedTransactions';
import SuccessfulTransactions from 'views/Mpesa/SuccessfulTransactions/SuccessfulTransactions';
import TextTemplates from 'views/bulkSMS/textTemplates/TextTemplates';
import { SentTexts } from 'views/bulkSMS/sendTexts';

const authProtectedRoutes = [
  { path: '/users/permissions', component: <Permissions /> },
  { path: '/users/UsersList', component: <UsersList /> },
  { path: '/users/groups', component: <GroupsList /> },
  { path: '/clients/ClientsList', component: <ClientsList /> },
  { path: '/clients/registration', component: <RegistrationList /> },
  { path: '/disbursements/releases', component: <Releases /> },
  { path: '/disbursements/payments', component: <Payments /> },
  { path: '/sms/sms-config', component: <SmsConfig /> },

  { path: '/sms/sent-texts', component: <SentTexts /> },
  { path: '/sms/text-templates', component: <TextTemplates /> },

  { path: '/mpesa/mpesa-config', component: <MpesaConfig /> },
  { path: '/mpesa/access-token', component: <AccessTokens /> },
  { path: '/mpesa/failed-transactions', component: <FailedTransactions /> },
  {
    path: '/mpesa/successful-transactions',
    component: <SuccessfulTransactions />,
  },

  { path: '/dashboard', component: <DashboardEcommerce /> },
  { path: '/index', component: <DashboardEcommerce /> },

  { path: '/apps-calendar', component: <Calendar /> },

  { path: '/apps-file-manager', component: <FileManager /> },
  { path: '/apps-todo', component: <ToDoList /> },
  { path: '/apps-api-key', component: <APIKey /> },

  //Chat
  { path: '/apps-chat', component: <Chat /> },

  //EMail
  { path: '/apps-mailbox', component: <MailInbox /> },
  { path: '/apps-email-basic', component: <BasicAction /> },
  { path: '/apps-email-ecommerce', component: <EcommerceAction /> },

  //Projects
  { path: '/apps-projects-list', component: <ProjectList /> },
  { path: '/apps-projects-overview', component: <ProjectOverview /> },
  { path: '/apps-projects-create', component: <CreateProject /> },

  //Task
  { path: '/apps-tasks-kanban', component: <Kanbanboard /> },
  { path: '/apps-tasks-list-view', component: <TaskList /> },
  { path: '/apps-tasks-details', component: <TaskDetails /> },

  //Invoices
  { path: '/apps-invoices-list', component: <InvoiceList /> },
  { path: '/apps-invoices-details', component: <InvoiceDetails /> },
  { path: '/apps-invoices-create', component: <InvoiceCreate /> },

  //Supports Tickets
  { path: '/apps-tickets-list', component: <ListView /> },
  { path: '/apps-tickets-details', component: <TicketsDetails /> },

  // Widgets
  { path: '/widgets', component: <Widgets /> },

  //Tables
  { path: '/tables-basic', component: <BasicTables /> },
  { path: '/tables-react', component: <ReactTable /> },

  //Maps
  { path: '/maps-google', component: <GoogleMaps /> },

  //Pages
  { path: '/pages-starter', component: <Starter /> },
  { path: '/pages-profile', component: <SimplePage /> },
  { path: '/pages-profile-settings', component: <Settings /> },
  { path: '/pages-team', component: <Team /> },
  { path: '/pages-timeline', component: <Timeline /> },
  { path: '/pages-faqs', component: <Faqs /> },
  { path: '/pages-gallery', component: <Gallery /> },
  { path: '/pages-pricing', component: <Pricing /> },
  { path: '/pages-sitemap', component: <SiteMap /> },
  { path: '/pages-search-results', component: <SearchResults /> },
  { path: '/pages-privacy-policy', component: <PrivacyPolicy /> },
  { path: '/pages-term-conditions', component: <TermsCondition /> },

  //User Profile
  { path: '/profile', component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  { path: '*', component: <ComingSoon /> },
];

const publicRoutes = [
  // Authentication Page
  { path: '/logout', component: <Logout /> },
  { path: '/login', component: <Login /> },
  { path: '/forgot-password', component: <ForgetPasswordPage /> },
  { path: '/register', component: <Register /> },
  { path: '/enter-otp', component: <EnterOTP /> },

  //AuthenticationInner pages
  { path: '/auth-signin-basic', component: <BasicSignIn /> },
  { path: '/auth-signin-cover', component: <CoverSignIn /> },
  { path: '/auth-signup-basic', component: <BasicSignUp /> },
  { path: '/auth-signup-cover', component: <CoverSignUp /> },
  { path: '/auth-pass-reset-basic', component: <BasicPasswReset /> },
  { path: '/auth-pass-reset-cover', component: <CoverPasswReset /> },
  { path: '/auth-lockscreen-basic', component: <BasicLockScreen /> },
  { path: '/auth-lockscreen-cover', component: <CoverLockScreen /> },
  { path: '/auth-logout-basic', component: <BasicLogout /> },
  { path: '/auth-logout-cover', component: <CoverLogout /> },
  { path: '/auth-success-msg-basic', component: <BasicSuccessMsg /> },
  { path: '/auth-success-msg-cover', component: <CoverSuccessMsg /> },
  { path: '/auth-twostep-basic', component: <BasicTwosVerify /> },
  { path: '/auth-twostep-cover', component: <CoverTwosVerify /> },
  { path: '/auth-404-basic', component: <Basic404 /> },
  { path: '/auth-404-cover', component: <Cover404 /> },
  { path: '/auth-404-alt', component: <Alt404 /> },
  { path: '/auth-500', component: <Error500 /> },
  { path: '/pages-maintenance', component: <Maintenance /> },
  { path: '/pages-coming-soon', component: <ComingSoon /> },

  { path: '/auth-pass-change-basic', component: <BasicPasswCreate /> },
  { path: '/auth-pass-change-cover', component: <CoverPasswCreate /> },
  { path: '/auth-offline', component: <Offlinepage /> },
];

export { authProtectedRoutes, publicRoutes };
