/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Input,
  Label,
  FormGroup,
} from 'reactstrap';
import { Dropdown, Menu, Table } from 'antd';
import { toast } from 'react-toastify';
import { BASE_URL } from 'helpers';

interface Permission {
  id: number;
  name: string;
}

interface GroupDetail {
  id: number;
  name: string;
  permissions: number[];
  description: string | null;
  permissions_details: Permission[];
}

interface Group {
  permissions: number[];
  id: number;
  name: string;
  description: string;
  group_details: GroupDetail[];
}

interface EditGroupFormProps {
  group: Group;
  permissions: Permission[];
  onSubmit: (formData: Group) => void;
}

export const columns = (fetchGroups: any) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Role Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: Group) => (
      <ActionModalComponent
        text={text}
        record={record}
        fetchGroups={fetchGroups}
      />
    ),
  },
];

const EditGroupForm: React.FC<EditGroupFormProps> = ({ group, onSubmit }) => {
  const [formData, setFormData] = useState<Group>({
    ...group,
  });
  const [permissions, setPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    fetchPermissions();
  }, []);

  const fetchPermissions = async () => {
    try {
      const response = await fetch(`${BASE_URL}/users/permissions/`);
      if (!response.ok) {
        throw new Error('Failed to fetch permissions');
      }
      const data = await response.json();
      setPermissions(data);
    } catch (error) {
      console.error('Error fetching permissions:', error);
    }
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePermissionChange = (permissionId: number, checked: boolean) => {
    let updatedPermissions: number[];

    if (checked) {
      updatedPermissions = [...formData.permissions, permissionId];
    } else {
      updatedPermissions = formData.permissions.filter(
        (id) => id !== permissionId
      );
    }

    setFormData((prevState) => ({
      ...prevState,
      permissions: updatedPermissions,
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const permissionsColumns = [
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: Permission) => (
        <input
          type="checkbox"
          checked={formData.permissions.includes(record.id)}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handlePermissionChange(record.id, e.target.checked)
          }
        />
      ),
    },
    {
      title: 'Permission Name',
      dataIndex: 'name',
      key: 'name',
    },
    { title: 'Codename', dataIndex: 'codename', key: 'codename' },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="groupName">Group Name</Label>
        <Input
          id="groupName"
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label for="groupDescription">Group Description</Label>
        <Input
          id="groupDescription"
          type="text"
          name="description"
          value={formData.description}
          onChange={handleChange}
        />
      </FormGroup>
      <FormGroup>
        <Label>Permissions</Label>
        <Table
          columns={permissionsColumns}
          dataSource={permissions}
          rowKey="id"
          pagination={false}
        />
      </FormGroup>
      <Button type="submit">Save Changes</Button>
    </Form>
  );
};

const ActionModalComponent: React.FC<{
  text: string;
  record: Group;
  fetchGroups: () => void;
}> = ({ text, record, fetchGroups }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedGroup, setEditedGroup] = useState<Group | null>(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const toggle = () => {
    setIsModalVisible(!isModalVisible);
    setIsEditing(false);
    setEditedGroup(null);
  };

  const showDeleteConfirm = () => {
    setIsDeleteConfirmVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsDeleteConfirmVisible(false);
  };

  const handleEdit = (group: Group) => {
    setIsEditing(true);
    setEditedGroup(group);
    showModal();
  };

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(`${BASE_URL}/users/groups/${id}/`, {
        method: 'DELETE',
      });

      if (response.ok) {
        toast.success('Group deleted successfully');
        fetchGroups();
      } else {
        toast.error('An error occurred while deleting the group');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while deleting the group');
    }

    setIsDeleteConfirmVisible(false);
  };

  const handleEditSubmit = async (formData: Group) => {
    try {
      const response = await fetch(
        `${BASE_URL}/users/groups/${editedGroup?.id}/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        toast.success('Group updated successfully');
        fetchGroups();
        setIsModalVisible(false);
      } else {
        toast.error('An error occurred while updating the group');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while updating the group');
    }
  };

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit" onClick={() => handleEdit(record)}>
              Edit
            </Menu.Item>
            <Menu.Item key="delete" onClick={showDeleteConfirm}>
              Delete
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="4" r="1" />
            <circle cx="8" cy="8" r="1" />
            <circle cx="8" cy="12" r="1" />
          </svg>
        </a>
      </Dropdown>
      <Modal id="showModal" isOpen={isModalVisible} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {isEditing ? 'Edit Group' : 'Add Group'}
        </ModalHeader>
        <ModalBody>
          {isEditing && editedGroup ? (
            <EditGroupForm
              group={editedGroup}
              onSubmit={handleEditSubmit}
              permissions={[]}
            />
          ) : (
            <p>Add group form here</p>
          )}
        </ModalBody>
      </Modal>
      <Modal isOpen={isDeleteConfirmVisible} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>Confirm Delete</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete this group?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="danger" onClick={() => handleDelete(record.id)}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
