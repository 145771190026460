import React from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import SetTable from '../SetTable';
import { columns } from './Constants';

const SuccessfulTransactions = () => {
  document.title =
    'Successful Transactions | At Home Capital || Credit Management System';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Successful Transactions" pageTitle="MPESA" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Successful Transactions</h5>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <SetTable
                      columns={columns}
                      dataSource={[]}
                      loading={false}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SuccessfulTransactions;
