import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { fetchDisbursementStats, DisbursementStats } from '../../../data';
import { ToastContainer, toast } from 'react-toastify';

const DisbursementsStats = () => {
  const [stats, setStats] = useState<DisbursementStats | null>(null);

  useEffect(() => {
    fetchDisbursementStats()
      .then(setStats)
      .catch((error) =>
        toast.error(error instanceof Error ? error.message : String(error))
      );
  }, []);

  return (
    <>
      <ToastContainer />
      {stats && (
        <Col xl={3} md={6}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Total Disbursements
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className={'fs-14 mb-0 text-success'}>
                    <i
                      className={'fs-13 align-middle ri-arrow-right-up-line'}
                    ></i>
                    {+16.4} %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="559.25">
                      <CountUp
                        start={0}
                        prefix={''}
                        suffix={''}
                        separator={','}
                        end={stats.total_disbursements}
                        decimals={0}
                        duration={4}
                      />
                    </span>
                  </h4>
                  <Link
                    to="/disbursements"
                    className="text-decoration-underline"
                  >
                    View all Disbursements
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={'avatar-title rounded fs-3 bg-success'}>
                    <i className={`bx bx-shopping-bag`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
      {stats && (
        <Col xl={3} md={6}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Amount Disbursed
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className={'fs-14 mb-0 text-success'}>
                    <i
                      className={'fs-13 align-middle ri-arrow-right-up-line'}
                    ></i>
                    {+16.4} %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="559.25">
                      <CountUp
                        start={0}
                        prefix={'Ksh.'}
                        suffix={''}
                        separator={','}
                        end={stats.total_amount_disbursed}
                        decimals={2}
                        duration={4}
                      />
                    </span>
                  </h4>
                  <Link
                    to="/disbursements"
                    className="text-decoration-underline"
                  >
                    View all Disbursements
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={'avatar-title rounded fs-3 bg-success'}>
                    <i className={`bx bx-shopping-bag`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
      {stats && (
        <Col xl={3} md={6}>
          <Card className="card-animate">
            <CardBody>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                    Profit Expected
                  </p>
                </div>
                <div className="flex-shrink-0">
                  <h5 className={'fs-14 mb-0 text-success'}>
                    <i
                      className={'fs-13 align-middle ri-arrow-right-up-line'}
                    ></i>
                    {+16.4} %
                  </h5>
                </div>
              </div>
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                    <span className="counter-value" data-target="559.25">
                      <CountUp
                        start={0}
                        prefix={'Ksh.'}
                        suffix={''}
                        separator={','}
                        end={stats.total_expected_profit}
                        decimals={2}
                        duration={4}
                      />
                    </span>
                  </h4>
                  <Link
                    to="/disbursements"
                    className="text-decoration-underline"
                  >
                    View all Disbursements
                  </Link>
                </div>
                <div className="avatar-sm flex-shrink-0">
                  <span className={'avatar-title rounded fs-3 bg-success'}>
                    <i className={`bx bx-shopping-bag`}></i>
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  );
};

export default DisbursementsStats;
