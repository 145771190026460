import { BASE_URL } from 'helpers';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState<boolean>(false);
  const [isDisbursements, setIsDisbursements] = useState<boolean>(false);
  const [isUsers, setIsUsers] = useState<boolean>(false);
  const [isBulkSMS, setIsBulkSMS] = useState<boolean>(false);
  const [isMPESA, setIsMPESA] = useState<boolean>(false);
  const [isClients, setIsClients] = useState<boolean>(false);
  const [isReports, setIsReports] = useState<boolean>(false);
  const [iscurrentState, setIscurrentState] = useState('Dashboard');

  const [userRole, setUserRole] = useState(null); // State to store the user role

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch(`${BASE_URL}/users/get-user-from-token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Specify the content type
        },
        body: JSON.stringify({
          "token": token, // Include the token in the body as per your API
        }),
      })
        .then((response) => response.json())
        .then((data) => {
 
          setUserRole(data.group_details?.[0]?.name || '');
        })
        .catch((error) => console.error('Error fetching user details:', error));
    }
  }, []);

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute('sub-items')) {
      const ul: any = document.getElementById('two-column-menu');
      const iconItems = ul.querySelectorAll('.nav-icon.active');
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove('active');
        var id = item.getAttribute('sub-items');
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove('show');
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel');
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false);
    }
    if (iscurrentState !== 'Disbursements') {
      setIsDisbursements(false);
    }
    if (iscurrentState !== 'Users') {
      setIsUsers(false);
    }
    if (iscurrentState !== 'BulkSMS') {
      setIsBulkSMS(false);
    }
    if (iscurrentState !== 'MPESA') {
      setIsMPESA(false);
    }
    if (iscurrentState !== 'Clients') {
      setIsClients(false);
    }
    if (iscurrentState !== 'Reports') {
      setIsReports(false);
    }
    if (iscurrentState === 'Widgets') {
      history('/widgets');
      document.body.classList.add('twocolumn-panel');
    }
  }, [history, iscurrentState, isDashboard]);

  const menuItems: any = [
    {
      label: 'Menu',
      isHeader: true,
    },
    userRole === 'admin' && {
      id: 'dashboard',
      label: 'Dashboards',
      icon: 'mdi mdi-speedometer',
      link: '/#',
      stateVariables: isDashboard,
      click: function (e: any) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState('Dashboard');
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'ecommerce',
          label: 'Dashboard',
          link: '/dashboard',
          parentId: 'dashboard',
        },
      ],
    },
    {
      id: 'disbursements',
      label: 'Disbursements',
      icon: 'mdi mdi-cash-multiple',
      link: '/#',
      click: function (e: any) {
        e.preventDefault();
        setIsDisbursements(!isDisbursements);
        setIscurrentState('Disbursements');
        updateIconSidebar(e);
      },
      stateVariables: isDisbursements,
      subItems: [
        {
          id: 'releases',
          label: 'Releases',
          link: '/disbursements/releases',
          parentId: 'disbursements',
        },
        {
          id: 'payments',
          label: 'Payments',
          link: '/disbursements/payments',
          parentId: 'disbursements',
        },
        {
          id: 'overdue',
          label: 'Overdue',
          link: '/disbursements/overdue',
          parentId: 'disbursements',
        },
      ],
    },
    userRole === 'admin' && {
      id: 'users',
      label: 'User Management',
      icon: 'mdi mdi-account-multiple',
      link: '/#',
      click: function (e: any) {
        e.preventDefault();
        setIsUsers(!isUsers);
        setIscurrentState('Users');
        updateIconSidebar(e);
      },
      stateVariables: isUsers,
      subItems: [
        {
          id: 'usersList',
          label: 'Users',
          link: '/users/UsersList',
          parentId: 'users',
        },
        {
          id: 'groups',
          label: 'Groups',
          link: '/users/groups',
          parentId: 'users',
        },
        {
          id: 'permisssions',
          label: 'Permissions',
          link: '/users/permissions',
          parentId: 'users',
        },
      ],
    },
    {
      id: 'clients',
      label: 'Clients',
      icon: 'mdi mdi-account-star',
      link: '/#',
      click: function (e: any) {
        e.preventDefault();
        setIsClients(!isClients);
        setIscurrentState('Clients');
        updateIconSidebar(e);
      },
      stateVariables: isClients,
      subItems: [
        {
          id: 'clientList',
          label: 'Clients',
          link: '/clients/ClientsList',
          parentId: 'clients',
        },
        {
          id: 'clientList',
          label: 'Registration Fee',
          link: '/clients/registration',
          parentId: 'clients',
        },
      ],
    },
    userRole === 'admin' && {
      id: 'BulkSMS',
      label: 'Bulk SMS',
      icon: 'mdi mdi-message-processing',
      link: '/#',
      click: function (e: any) {
        e.preventDefault();
        setIsBulkSMS(!isBulkSMS);
        setIscurrentState('BulkSMS');
        updateIconSidebar(e);
      },
      stateVariables: isBulkSMS,
      subItems: [
        {
          id: 'smsConfig',
          label: 'SMS Configuration',
          link: '/sms/sms-config',
          parentId: 'BulkSMS',
        },
        {
          id: 'SentTexts',
          label: 'Sent Texts',
          link: '/sms/sent-texts',
          parentId: 'BulkSMS',
        },
        {
          id: 'TextTemplates',
          label: 'Text Templates',
          link: '/sms/text-templates',
          parentId: 'BulkSMS',
        },
      ],
    },
    {
      id: 'reports',
      label: 'Reports',
      icon: 'mdi mdi-file-chart-outline',
      link: '/#',
      click: function (e: any) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState('Reports');
        updateIconSidebar(e);
      },
      stateVariables: isReports,
      subItems: [
        {
          id: 'loans-due-today',
          label: 'Loans due today',
          link: '/report/loans-due-today',
          parentId: 'reports',
        },
        {
          id: 'loans-disbursed-today',
          label: 'Loans Disbursed today',
          link: '/report/loans-disbursed-today',
          parentId: 'reports',
        },
        {
          id: 'defaulted-loans',
          label: 'Defaulted loans',
          link: '/report/defaulted-loans',
          parentId: 'reports',
        },
        {
          id: 'loans-disbursed-date-range',
          label: 'Loans Disbursed (with date range)',
          link: '/report/loans-disbursed-date-range',
          parentId: 'reports',
        },
        {
          id: 'loans-due-date-range',
          label: 'Loans due(with date range)',
          link: '/report/loans-due-date-range',
          parentId: 'reports',
        },
        {
          id: 'customer-reports',
          label: 'Active customers, Dormant customers, New customers report',
          link: '/report/customer-reports',
          parentId: 'reports',
        },
        {
          id: 'repayment-report',
          label: 'Repayment Report',
          link: '/report/repayment-report',
          parentId: 'reports',
        },
      ],
    },
    userRole === 'admin' && {
      id: 'MPESA',
      label: 'MPESA',
      icon: 'mdi mdi-cash-100',
      link: '/#',
      click: function (e: any) {
        e.preventDefault();
        setIsMPESA(!isMPESA);
        setIscurrentState('MPESA');
        updateIconSidebar(e);
      },
      stateVariables: isMPESA,
      subItems: [
        {
          id: 'MPESA',
          label: 'MPESA Configuration',
          link: '/mpesa/mpesa-config',
          parentId: 'MPESA',
        },
        {
          id: 'calendar',
          label: 'Successful Transactions',
          link: '/mpesa/successful-transactions',
          parentId: 'MPESA',
        },
        {
          id: 'MPESA',
          label: 'Failed Transactions',
          link: '/mpesa/failed-transactions',
          parentId: 'MPESA',
        },
        {
          id: 'access-token',
          label: 'Access Token.',
          link: '/mpesa/access-token',
          parentId: 'MPESA',
        },
      ],
    },
  ].filter(Boolean); // This ensures only truthy items are included;
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
