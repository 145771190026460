import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { columns } from './Constants';
import SetTable from '../SetTable';
import { BASE_URL } from 'helpers';

const FailedTransactions = () => {
  // State to store fetched transactions
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    // Function to fetch transactions
    const fetchTransactions = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/disbursements/api/callbacks/failed/`
        ); // Adjust the URL as needed
        const data = await response.json();
        setTransactions(data); // Set the fetched transactions to state
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    // Call the fetch function
    fetchTransactions();
  }, []); // Empty dependency array ensures this effect runs only once after the initial render
  document.title =
    'Failed Transactions | At Home Capital || Credit Management System';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Failed Transactions" pageTitle="MPESA" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Failed Transactions</h5>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <SetTable
                      columns={columns}
                      dataSource={transactions}
                      loading={false}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FailedTransactions;
