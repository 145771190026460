import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Modal,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Input,
  FormFeedback,
  Button,
} from 'reactstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { SetTable } from '../ClientsTable';
import { columns } from './Constants';
import { BASE_URL } from 'helpers';
import { ToastContainer, toast } from 'react-toastify';

type Registration = {
  id: number;
  amount: string;
  payment_date: string;
  is_paid: boolean;
  client: number;
};

const RegistrationList = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [registration, setRegistration] = useState<Registration[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [clients, setClients] = useState<any[]>([]); // New state to store clients

  const fetchRegistration = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}/registration-fee/registration-fees/`
      );
      if (!response.ok) {
        throw new Error('Something went wrong');
      }
      const data = await response.json();
      setRegistration(data.data);
    } catch (error) {
      console.error('Error fetching registration:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRegistration();
  }, []);

  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/clients/`);
      if (!response.ok) {
        throw new Error('Something went wrong');
      }
      const data = await response.json();
      setClients(data.data);
    } catch (error) {
      console.error('Error fetching clients:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const toggle = useCallback(() => {
    setModal((prevModal) => !prevModal);
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: '',
      payment_date: '',
      is_paid: true,
      client: 0,
    },
    validationSchema: Yup.object({
      amount: Yup.string().required('Amount is required'),
      payment_date: Yup.string().required('Payment date is required'),
      is_paid: Yup.boolean(),
      client: Yup.number().required('Client is required'),
    }),
    onSubmit: (values) => {
      const registrationData = {
        amount: values.amount,
        payment_date: values.payment_date,
        is_paid: values.is_paid,
        client: values.client,
      };
      saveRegistration(registrationData)
        .then(() => {
          toggle();
          validation.resetForm();
        })
        .catch((error) => console.error('Error saving registration:', error));
    },
  });

  const saveRegistration = async (registrationData: {
    amount: string;
    payment_date: string;
    is_paid: boolean;
    client: number;
  }) => {
    try {
      const method = 'POST';
      const url = `${BASE_URL}/registration-fee/registration-fees/`;
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });

      if (!response.ok) {
        throw new Error('Failed to save registration');
      }
      const data = await response.json();
      toast.success('Registration saved successfully');
      return data;
    } catch (error) {
      toast.error(
        error instanceof Error
          ? error.message
          : 'An error occurred while saving the registration'
      );
      throw error;
    } finally {
      fetchRegistration();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Registration" pageTitle="Client Management" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Registration</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <button
                          type="button"
                          className="btn btn-success add-btn me-1"
                          id="create-btn"
                          onClick={toggle}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Registration
                        </button>{' '}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <SetTable
                      columns={columns}
                      dataSource={registration}
                      loading={isLoading}
                    />
                  </div>

                  <Modal isOpen={modal} toggle={toggle} centered>
                    <ModalHeader toggle={toggle}>Add Registration</ModalHeader>
                    <Form onSubmit={validation.handleSubmit}>
                      <ModalBody>
                        <div className="mb-3">
                          <Label for="amount">Amount</Label>
                          <Input
                            id="amount"
                            name="amount"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.amount}
                            invalid={
                              validation.touched.amount &&
                              Boolean(validation.errors.amount)
                            }
                          />
                          <FormFeedback>
                            {validation.errors.amount}
                          </FormFeedback>
                        </div>

                        <div className="mb-3">
                          <Label for="payment_date">Payment Date</Label>
                          <Input
                            id="payment_date"
                            name="payment_date"
                            type="date"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.payment_date}
                            invalid={
                              validation.touched.payment_date &&
                              Boolean(validation.errors.payment_date)
                            }
                          />
                          <FormFeedback>
                            {validation.errors.payment_date}
                          </FormFeedback>
                        </div>

                        <div className="mb-3">
                          <Label for="is_paid">Is Paid</Label>
                          <Input
                            id="is_paid"
                            name="is_paid"
                            type="checkbox"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            checked={validation.values.is_paid}
                          />
                        </div>

                        <div className="mb-3">
                          <Label for="client">Client</Label>
                          <Input
                            type="select"
                            name="client"
                            id="client"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.client}
                            invalid={
                              validation.touched.client &&
                              Boolean(validation.errors.client)
                            }
                          >
                            <option value="">Select Client</option>
                            {clients.map((client) => (
                              <option key={client.id} value={client.id}>
                                {client.name}
                              </option>
                            ))}
                          </Input>
                          <FormFeedback>
                            {validation.errors.client}
                          </FormFeedback>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                          Cancel
                        </Button>
                        <Button type="submit" color="primary">
                          Add Registration
                        </Button>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RegistrationList;
