import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { BASE_URL } from 'helpers';

const Section = (props: any) => {
  const [userName, setUserName] = useState(''); // State for storing the username

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch(`${BASE_URL}/users/get-user-from-token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          return response.json();
        })
        .then((data) => {
          setUserName(data.username); // Update the username state
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
        });
    } else {
      console.error('Token not found in localStorage');
    }
  }, []);

  // Function to get the current time in Nairobi and determine the greeting
  const getGreeting = () => {
    // Create a Date object for the current time in UTC
    const now = new Date();
    // Convert current time to Nairobi time (UTC+3)
    const nairobiTime = new Date(now.getTime() + 3 * 60 * 60 * 1000);
    const hour = nairobiTime.getHours();

    // Determine the greeting based on the hour
    if (hour < 12) {
      return 'Good Morning';
    } else if (hour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };

  // Use the getGreeting function to set the greeting message
  const greeting = getGreeting();

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              {/* Use the dynamic greeting here */}
              <h4 className="fs-16 mb-1 text-capitalize">
                {greeting}, {userName}!
              </h4>
              <p className="text-muted mb-0">Here's what's happening today.</p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-sm-auto">
                    <div className="input-group">
                      <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        options={{
                          mode: 'single', // Changed from 'range' to 'single'
                          dateFormat: 'd - M - Y',
                          defaultDate: new Date(), // Set to current date
                        }}
                      />
                      <div className="input-group-text bg-primary border-primary text-white">
                        <i className="ri-calendar-2-line"></i>
                      </div>
                    </div>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
