import React from 'react';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import SetTable from './setTable/SetTable';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },

  {
    title: 'Client Name',
    dataIndex: ['client', 'name'],
    key: 'client_name',
  },

  {
    title: 'Due Amount',
    dataIndex: 'due_amount',
    key: 'due_amount',
  },
  {
    title: 'Remaining Balance',
    dataIndex: 'remaining_balance',
    key: 'remaining_balance',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Due Date',
    dataIndex: 'due_date',
    key: 'due_date',
  },
];
const RecentOrders = () => {
  return (
    <React.Fragment>
      <Col xl={8}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Recent Disbursements
            </h4>
            <div className="flex-shrink-0">
              <button
                type="button"
                className="btn btn-soft-info btn-sm shadow-none"
              >
                <i className="ri-file-list-3-line align-middle"></i> Generate
                Report
              </button>
            </div>
          </CardHeader>

          <CardBody>
            <div className="table-responsive table-card">
              <SetTable columns={columns} dataSource={[]} loading={false} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RecentOrders;
