import React from 'react';
import { Col } from 'reactstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';

// Import Images
import logoLight from '../../assets/images/logo-light.png';

const AuthSlider = () => {
  return (
    <React.Fragment>
      <Col lg={6}style={{border: 'solid 2px #040468'}}>
        <div className="p-lg-5 p-4 h-100">
          {/* <div className="bg-overlay"></div> */}
          <div className="position-relative h-100 d-flex flex-column">
            <div className="mb-4 mx-auto">
              <Link to="/" className="d-block">
                <img
                  src={logoLight}
                  alt="aprpms"
                  style={{ height: '200px', width: '200px' }}
                />
              </Link>
            </div>
            <div className="text-center mx-auto mt-5 text-primary">
              {/* <h1 className="text-secondary">APRPMS</h1> */}
              <h1 className="text-secondary">Credit Management System</h1>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default AuthSlider;
