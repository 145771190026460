import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from 'reactstrap';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import withRouter from '../../Components/Common/withRouter';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';
// actions
import { createSelector } from 'reselect';
import AuthSlider from './authCarousel';
import axios from 'axios';
//import images
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from 'helpers';

const Login = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectLayoutState = (state: any) => state;
  const loginpageData = createSelector(selectLayoutState, (state) => ({
    user: state.Account.user,
    error: state.Login.error,
    errorMsg: state.Login.errorMsg,
  }));
  // Inside your component
  const { error, errorMsg } = useSelector(loginpageData);

  const [userLogin, setUserLogin] = useState<any>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: userLogin.email || 'admin@themesbrand.com' || '',
      password: userLogin.password || '123456' || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (values) => {
      setLoader(true);
    },
  });

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const username = formData.get('username') as string;
    const password = formData.get('password') as string;

    if (!username || !password) {
      if (!username) toast.error('Username is required');
      if (!password) toast.error('Password is required');
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/auth/token/`, {
        username,
        password,
      });
      const {
        access,
        refresh,
        email,
        national_id,
        username: usr,
        phone_number,
        access_expires, // Assuming these fields are included in the response
        refresh_expires,
      } = response.data;

      // Assuming the OTP is sent as part of the login response or separately
      // Send OTP and expect the OTP value in the response
      const otpResponse = await axios.post(`${BASE_URL}/sms/send-otp/`, {
        phone_number: phone_number,
        email: email,
      });
      console.log(otpResponse);
      // Extract OTP from the response
      const otp = otpResponse.data.otp;

      toast.success('Login and OTP sending successful.');
      sessionStorage.setItem('username', usr);
      sessionStorage.setItem('nationalID', national_id);
      sessionStorage.setItem('email', email);
      sessionStorage.setItem('phoneNumber', phone_number);
      sessionStorage.setItem('accessToken', access);
      sessionStorage.setItem('accessExpires', access_expires); // Store access token expiration
      localStorage.setItem('refreshToken', refresh);
      localStorage.setItem('refreshExpires', refresh_expires); // Store refresh token expiration in local storage
      sessionStorage.setItem('otp', otp);
      navigate('/enter-otp');
    } catch (error) {
      toast.error(
        'An error occurred. Please check your credentials and try again.'
      );
      console.error('Error during form submission:', error);
      // Optionally navigate to error page or handle error
    }
  };

  document.title = 'SignIn | At Home Capital || Credit Management System';

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden">
                  <Row className="g-0">
                    <AuthSlider />
                    <Col lg={6} style={{ border: 'solid 2px #040468' }}>
                      <div className="p-lg-5 p-4">
                        <>
                          <>
                            <div>
                              <h5 className="text-primary">Welcome!</h5>
                              <p className="text-muted capitalize">
                                Sign in to continue.
                              </p>
                            </div>
                            {error && error ? (
                              <Alert color="danger"> {error} </Alert>
                            ) : null}
                            <div className="p-2 mt-4">
                              <Form onSubmit={handleFormSubmit}>
                                <div className="mb-3">
                                  <Label htmlFor="text" className="form-label">
                                    Username
                                  </Label>
                                  <Input
                                    name="username"
                                    className="form-control"
                                    placeholder="Enter Username"
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.email &&
                                      validation.errors.email
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.email &&
                                  validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.email}
                                    </FormFeedback>
                                  ) : null}
                                </div>

                                <div className="mb-3">
                                  <div className="float-end">
                                    <Link
                                      to="/forgot-password"
                                      className="text-muted"
                                    >
                                      Forgot password?
                                    </Link>
                                  </div>
                                  <Label
                                    className="form-label"
                                    htmlFor="password-input"
                                  >
                                    Password
                                  </Label>
                                  <div className="position-relative auth-pass-inputgroup mb-3">
                                    <Input
                                      name="password"
                                      type={showPassword ? 'text' : 'password'}
                                      className="form-control pe-5"
                                      placeholder="Enter Password"
                                      onBlur={validation.handleBlur}
                                      invalid={
                                        validation.touched.password &&
                                        validation.errors.password
                                          ? true
                                          : false
                                      }
                                    />
                                    {validation.touched.password &&
                                    validation.errors.password ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.password}
                                      </FormFeedback>
                                    ) : null}
                                    <button
                                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      type="button"
                                      id="password-addon"
                                    >
                                      <i className="ri-eye-fill align-middle"></i>
                                    </button>
                                  </div>
                                </div>

                                <div className="form-check">
                                  <Input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="auth-remember-check"
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="auth-remember-check"
                                  >
                                    Remember me
                                  </Label>
                                </div>

                                <div className="mt-4">
                                  <Button
                                    color="success"
                                    disabled={loader && true}
                                    className="btn btn-success w-100"
                                    type="submit"
                                  >
                                    {loader && (
                                      <Spinner size="sm" className="me-2">
                                        {' '}
                                        Loading...{' '}
                                      </Spinner>
                                    )}
                                    Sign In
                                  </Button>
                                </div>
                              </Form>
                            </div>
                          </>
                        </>

                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            Don't have an account? <br />
                            <p className="fw-semibold text-primary text-decoration-underline">
                              Contact Admin
                            </p>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p className="mb-0">
                    &copy; {new Date().getFullYear()} At Home Capital. Crafted
                    with <i className="mdi mdi-heart text-danger"></i> by{' '}
                    <a
                      href="https://devsgraphics.co.ke/"
                      className="fw-semibold text-secondary text-decoration-underline"
                    >
                      Devs & Graphics KE
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
