/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  FormEvent,
  ChangeEvent,
  useCallback,
  useEffect,
} from 'react';
import { Tag, Dropdown, Menu } from 'antd';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Form,
  Input,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { BASE_URL } from 'helpers';

interface GroupDetail {
  id: number;
  name: string;
  permissions: number[];
  description: string | null;
  permissions_details: Array<{
    id: number;
    name: string;
    codename: string;
  }>;
}

interface UserData {
  id: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  is_employee: boolean;
  national_id: string;
  password: string;
  groups: any; // Consider specifying a more accurate type if possible
  group_details: GroupDetail[]; // Add this line
}

interface UserData {
  groups: any;
  id: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  password: string;
  is_employee: boolean;
  national_id: string;
}

interface Group {
  id: number;
  name: string;
}

export const columns = (fetchUsers: any) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
    key: 'first_name',
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    key: 'last_name',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  {
    title: 'Employee Status',
    dataIndex: 'is_employee',
    key: 'is_employee',
    render: (text: any, record: { is_employee: any }) =>
      record.is_employee ? 'Yes' : 'No',
  },
  {
    title: 'National ID',
    dataIndex: 'national_id',
    key: 'national_id',
  },
  {
    title: 'Groups',
    dataIndex: 'group_details',
    key: 'group_details',
    render: (group_details: any[]) => (
      <>
        {group_details.map((group_detail) => (
          <Tag color="blue" key={group_detail.id}>
            {group_detail.name}
          </Tag>
        ))}
      </>
    ),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: UserData) => (
      <ActionModalComponent
        text={text}
        record={record}
        fetchUsers={fetchUsers}
      />
    ),
  },
];

interface EditUserFormProps {
  user: UserData;
  onSubmit: (formData: UserData) => void;
}

const EditUserForm: React.FC<EditUserFormProps> = ({ user, onSubmit }) => {
  const [formData, setFormData] = useState<UserData>({
    ...user,
    id: '', // Add this line with an appropriate value
    username: user.username,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    phone_number: user.phone_number,
    is_employee: user.is_employee,
    password: user.password,
    national_id: user.national_id,
    groups: user.group_details
      ? user.group_details.map((group) => group.id)
      : [], // Assuming `group_details` is the correct source // Set to the first group's ID if available
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const [groups, setGroups] = useState<Group[]>([]);
  // If user can belong to multiple groups, you might want to initialize this with multiple IDs
  const [selectedGroupId, setSelectedGroupId] = useState<string>(
    user.group_details && user.group_details.length > 0
      ? user.group_details[0].id.toString()
      : ''
  );

  useEffect(() => {
    fetch('https://django.athomecaptial.co.ke/users/groups/')
      .then((response) => response.json())
      .then((data) => setGroups(data))
      .catch((error) => console.error('Error fetching groups:', error));
  }, []);

  const handleGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newGroupId = event.target.value;
    setSelectedGroupId(newGroupId);
    // Update formData to reflect the new group selection, keeping it as an array for backend compatibility
    setFormData((prevFormData) => ({ ...prevFormData, groups: [newGroupId] }));
  };

  return (
    <Form className="tablelist-form" onSubmit={handleSubmit}>
      <div className="mb-3">
        <label>Username:</label>
        <Input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>Email:</label>
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>First Name:</label>
        <Input
          type="text"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>Last Name:</label>
        <Input
          type="text"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>Phone Number:</label>
        <Input
          type="text"
          name="phone_number"
          value={formData.phone_number}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>Employee Status:</label>{' '}
        <Input
          type="checkbox"
          name="is_employee"
          checked={formData.is_employee}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>National ID:</label>
        <Input
          type="text"
          name="national_id"
          value={formData.national_id}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>Group</label>
        <select
          name="group"
          id="groupSelect"
          value={selectedGroupId}
          onChange={handleGroupChange} // Ensure this matches the event type
        >
          {groups.map((group) => (
            <option key={group.id} value={group.id}>
              {group.name}
            </option>
          ))}
        </select>
      </div>
      <Button type="submit">Save Changes</Button>
    </Form>
  );
};

const ActionModalComponent = ({
  text,
  record,
  fetchUsers,
}: {
  text: string;
  record: UserData;
  fetchUsers: () => Promise<void>;
}) => {
  // Define state for showing the edit/delete modal
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isDeleteConfirmVisible, setIsDeleteConfirmVisible] =
    React.useState(false);

  const showModal = () => {
    console.log('Opening edit modal...');
    setIsModalVisible(true);
  };
  const toggle = useCallback(() => {
    setIsModalVisible((prevModal) => !prevModal);
  }, []);

  const showDeleteConfirm = () => {
    console.log('Opening delete confirmation...');
    setIsDeleteConfirmVisible(true);
  };

  const handleCancel = () => {
    console.log('Closing modal...');
    setIsModalVisible(false);
    setIsDeleteConfirmVisible(false);
  };

  const handleEdit = async (id: any, userData: {}) => {
    try {
      const response = await fetch(`${BASE_URL}/users/users/${id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        toast.success('User updated successfully');
        fetchUsers();
      } else {
        toast.error('An error occurred while updating the user');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while updating the user');
    }

    setIsModalVisible(false);
  };

  const handleDelete = async (id: any) => {
    try {
      const response = await fetch(`${BASE_URL}/users/users/${id}/`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        toast.success('User deleted successfully');
        fetchUsers();
      } else {
        toast.error('An error occurred while deleting the user');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while deleting the user');
    }
  
    setIsDeleteConfirmVisible(false);
  };
  

  // Define the JSX for your edit form and delete confirmation here

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="edit" onClick={showModal}>
              Edit
            </Menu.Item>
            <Menu.Item key="delete" onClick={showDeleteConfirm}>
              Delete
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="4" r="1" />
            <circle cx="8" cy="8" r="1" />
            <circle cx="8" cy="12" r="1" />
          </svg>
        </a>
      </Dropdown>
      {/* Modal for editing */}
      <Modal id="showModal" isOpen={isModalVisible} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          {'Edit User'}
        </ModalHeader>
        <ModalBody>
          <EditUserForm
            user={record}
            onSubmit={(updatedUser) => handleEdit(record.id, updatedUser)}
          />
        </ModalBody>
      </Modal>
      {/* Modal for delete confirmation */}
      <Modal
        title="Confirm Delete"
        isOpen={isDeleteConfirmVisible}
        onOk={() => handleDelete(record.id)}
        toggle={handleCancel}
      >
        <ModalBody>
          <p>Are you sure you want to delete this user?</p>
        </ModalBody>
        <ModalFooter>
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
          ,
          <Button
            key="submit"
            color="danger"
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
