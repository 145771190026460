export const columns = [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    title: 'Result Type',
    dataIndex: 'result_type',
    key: 'result_type',
  },
  {
    title: 'Result Code',
    dataIndex: 'result_code',
    key: 'result_code',
  },
  {
    title: 'Description',
    dataIndex: 'result_desc',
    key: 'result_desc',
  },
  {
    title: 'Originator Conversation ID',
    dataIndex: 'originator_conversation_id',
    key: 'originator_conversation_id',
  },
  {
    title: 'Conversation ID',
    dataIndex: 'conversation_id',
    key: 'conversation_id',
  },
  {
    title: 'Transaction ID',
    dataIndex: 'transaction_id',
    key: 'transaction_id',
  },
];
