/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useState, FormEvent, ChangeEvent } from 'react';
import { Tag, Dropdown, Menu } from 'antd';
import { Modal, ModalBody, Button, Form, Input, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { BASE_URL } from 'helpers';
interface ClientData {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
  national_id: string;
  address: string;
  is_active: boolean;
  // Add other client data fields as needed
}

interface EditClientFormProps {
  client: ClientData;
  onSubmit: (formData: ClientData) => void;
}

export const columns = (fetchClients: any) => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  {
    title: 'National ID',
    dataIndex: 'national_id',
    key: 'national_id',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Active Status',
    dataIndex: 'is_active',
    key: 'is_active',
    render: (text: any, record: { is_active: any }) => (
      <Tag color={record.is_active ? 'green' : 'red'}>
        {record.is_active ? 'Active' : 'Deactivated'}
      </Tag>
    ),
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (text: moment.MomentInput) =>
      moment(text).format('YYYY-MM-DD HH:mm:ss'), // Formatting the date
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (text: string, record: ClientData) => (
      <ActionModalComponent
        text={text}
        record={record}
        fetchClients={fetchClients}
      />
    ),
  },
];

const ActionModalComponent = ({
  text,
  record,
  fetchClients,
}: {
  text: string;
  record: ClientData;
  fetchClients: () => Promise<void>;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [isToggleModalVisible, setIsToggleModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true);
  const closeModal = () => {
    setIsModalVisible(false);
    setIsEditing(false);
    setIsToggleModalVisible(false);
  };

  const handleToggleStatus = async () => {
    try {
      const response = await fetch(
        `${BASE_URL}/clients/api/toggle-status/${record.id}/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        toast.success(
          `record ${
            record.is_active ? 'deactivated' : 'activated'
          } successfully`
        );
        fetchClients();
      } else {
        toast.error(
          `An error occurred while ${
            record.is_active ? 'deactivating' : 'activating'
          } the client`
        );
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(
        `An error occurred while ${
          record.is_active ? 'deactivating' : 'activating'
        } the client`
      );
    } finally {
      setIsToggleModalVisible(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    showModal();
  };

  const handleEditSubmit = async (editedClientData: any) => {
    try {
      const response = await fetch(`${BASE_URL}/clients/${record.id}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedClientData),
      });

      if (response.ok) {
        toast.success('Client updated successfully');
        fetchClients();
        closeModal();
      } else {
        toast.error('An error occurred while updating the client');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred while updating the client');
    } finally {
      setIsModalVisible(false);
    }
  };

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="toggleStatus"
              onClick={() => setIsToggleModalVisible(true)}
            >
              {record.is_active ? 'Deactivate' : 'Activate'}
            </Menu.Item>
            <Menu.Item key="edit" onClick={handleEdit}>
              Edit
            </Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <circle cx="8" cy="4" r="1" />
            <circle cx="8" cy="8" r="1" />
            <circle cx="8" cy="12" r="1" />
          </svg>
        </a>
      </Dropdown>

      <Modal isOpen={isModalVisible} toggle={closeModal} centered>
        <ModalBody>
          {isEditing ? (
            <EditClientForm client={record} onSubmit={handleEditSubmit} />
          ) : (
            <p>Are you sure you want to perform this action?</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isToggleModalVisible}
        toggle={() => setIsToggleModalVisible(false)}
        centered
      >
        <ModalBody>
          <p>
            Are you sure you want to{' '}
            {record.is_active ? 'Deactivate' : 'Activate'} this client?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setIsToggleModalVisible(false)}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={handleToggleStatus}>
            {record.is_active ? 'Deactivate' : 'Activate'}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const EditClientForm: React.FC<EditClientFormProps> = ({
  client,
  onSubmit,
}) => {
  const [formData, setFormData] = useState<ClientData>({ ...client });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label>Name:</label>
        <Input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>Email:</label>
        <Input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>Phone Number:</label>
        <Input
          type="text"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>National ID</label>
        <Input
          id="national_id"
          name="national_id"
          type="text"
          onChange={handleChange}
          value={formData.national_id}
        />
      </div>
      <div className="mb-3">
        <label>Address:</label>
        <Input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
        />
      </div>
      <div className="mb-3">
        <label>Active Status:</label>{' '}
        <Input
          type="checkbox"
          name="is_active"
          checked={formData.is_active}
          onChange={handleChange}
        />
      </div>
      {/* Add other input fields for client data */}
      <Button type="submit">Save Changes</Button>
    </Form>
  );
};
