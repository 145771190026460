import moment from "moment";

export const columns = [
    {
      title: 'Result Description',
      dataIndex: 'ResultDesc',
      key: 'ResultDesc',
    },
    {
      title: 'Transaction Amount',
      dataIndex: 'TransactionAmount',
      key: 'TransactionAmount',
    },
    {
      title: 'Transaction Receipt',
      dataIndex: 'TransactionReceipt',
      key: 'TransactionReceipt',
    },
    {
      title: 'Receiver Party Public Name',
      dataIndex: 'ReceiverPartyPublicName',
      key: 'ReceiverPartyPublicName',
    },
    {
      title: 'Transaction Completed Date Time',
      dataIndex: 'TransactionCompletedDateTime',
      key: 'TransactionCompletedDateTime',
    },
    {
      title: 'Utility Account Available Funds',
      dataIndex: 'B2CUtilityAccountAvailableFunds',
      key: 'B2CUtilityAccountAvailableFunds',
    },
    {
      title: 'Working Account Available Funds',
      dataIndex: 'B2CWorkingAccountAvailableFunds',
      key: 'B2CWorkingAccountAvailableFunds',
    },
    {
      title: 'Recipient Is Registered Customer',
      dataIndex: 'B2CRecipientIsRegisteredCustomer',
      key: 'B2CRecipientIsRegisteredCustomer',
    },
    {
      title: 'Charges Paid Account Available Funds',
      dataIndex: 'B2CChargesPaidAccountAvailableFunds',
      key: 'B2CChargesPaidAccountAvailableFunds',
    },
    // Add any other external columns as needed
  ];