import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { fetchClientCount, ClientCount } from '../../../data';

const ClientStats = () => {
  const [clientCount, setClientCount] = useState<ClientCount | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchClientCount()
      .then(setClientCount)
      .catch((error) =>
        setError(error instanceof Error ? error.message : String(error))
      );
  }, []);

  // if (error) return <div>Error loading client count: {error}</div>;

  return (
    <Col xl={3} md={6}>
      <Card className="card-animate">
        <CardBody>
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                No. of Clients
              </p>
            </div>
            <div className="flex-shrink-0">
              <h5 className={'fs-14 mb-0 text-dander'}>
                <i className={'fs-13 align-middle ri-arrow-right-down-line'}></i>
                {+16.4} %
              </h5>
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-between mt-4">
            <div>
              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                <span className="counter-value" data-target="559.25">
                  {clientCount && (
                    <CountUp
                      start={0}
                      prefix={''}
                      suffix={''}
                      separator={','}
                      end={clientCount.client_count || 0}
                      decimals={2}
                      duration={4}
                    />
                  )}
                </span>
              </h4>
              <Link to="#" className="text-decoration-underline">
                view all clients
              </Link>
            </div>
            <div className="avatar-sm flex-shrink-0">
              <span className={'avatar-title rounded fs-3 bg-danger'}>
                <i className={`ri-arrow-right-down-line`}></i>
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ClientStats;
