// import moment from 'moment';
import React from 'react';
import Table from '../../../Components/Tables/Table';
// import { useSelector } from 'react-redux';

interface Props {
  columns: any[];
  dataSource: any[];
  loading: boolean; // Add this line
}

const SetTable: React.FC<Props> = (props) => {
  const { columns, dataSource } = props;
  // const csvName = dataSource.map((item) => item.record)[0];

  const formatData = (data: any) =>
    data.map((item: any, index: number) => {
      return {
        ...item,
        key: index,
      };
    });

  const allColumns = [...columns];
  return (
    <Table
      showDownload
      showSearch
      loading={props.loading}
      dataSource={formatData(dataSource.sort((a, b) => b.id - a.id))}
      columns={allColumns}
      // csvName={csvName + ' ' + 'Statement'}
    />
  );
};

export default SetTable;
