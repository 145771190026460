import React from 'react';
import Table from '../../../Components/Tables/Table';
import { TableProps } from 'antd/lib/table';

interface Props {
  columns: any[];
  dataSource?: any[]; // Make dataSource optional or ensure it's always an array
  loading: boolean;
  expandable?: TableProps<any>['expandable'];
}

const SetTable: React.FC<Props> = (props) => {
  const { columns, dataSource, loading, expandable } = props;

  const formatData = (
    data: any[] // Ensure data is typed as an array
  ) =>
    data.map((item: any, index: number) => ({
      ...item,
      key: index,
    }));

  const allColumns = [...columns];
  return (
    <Table
      showDownload
      showSearch
      loading={loading}
      dataSource={
        dataSource ? formatData(dataSource.sort((a, b) => b.id - a.id)) : []
      } // Check if dataSource exists
      columns={allColumns}
      expandable={expandable}
    />
  );
};

export default SetTable;
