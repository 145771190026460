import React, { useState, useEffect} from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
} from 'reactstrap';
//Import Breadcrumb
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { SetTable } from '../UsersTable';
import { columns } from './Constants';
import { BASE_URL } from 'helpers';
import { ToastContainer } from 'react-toastify';
// Updated type definition to include all fields
type User = {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  is_employee: boolean;
  national_id: string;
};

const Permissions = () => {;
  // State to hold the permissions data
  const [permissions, setPermissions] = useState<User[]>([]);
  // State to handle loading status
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // State to handle any error
  const fetchPermissions = async () => {
    try {
      // Adjust the endpoint as necessary
      const response = await fetch(`${BASE_URL}/users/permissions/`);
      if (!response.ok) {
        throw new Error('Something went wrong'); // Handle non-2xx responses
      }
      const data = await response.json();
      setPermissions(data); // Assuming the API response has a 'data' field
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, []); // Empty dependency array means this effect runs once after the initial render

  document.title = 'Permissions | At Home Capital || Credit Management System';
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Permissions" pageTitle="User Management" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Permissions</h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <SetTable
                      columns={columns}
                      dataSource={permissions}
                      loading={isLoading}
                    />
                  </div>

                  <ToastContainer closeButton={false} limit={1} />
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Permissions;
