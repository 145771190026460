import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { TableProps } from 'antd/lib/table';
import React, { ChangeEvent, useState } from 'react';
//@ts-ignore
import { CSVLink } from 'react-csv';
// import { SearchInput } from '../Forms';
// import  './Table.scss';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Button, Row, Col } from 'reactstrap';

interface Props extends TableProps<any> {
  bordered?: boolean;
  showSearch?: boolean;
  showDownload?: boolean;
  csvName?: string;
}

interface SortedInfo {
  order?: 'ascend' | 'descend';
  columnKey?: string;
}

interface Pagination {
  currentPage: number;
  pageSize: number;
  // add other known properties here
}

const TableComponent: React.FC<Props> = (props: any) => {
  const {
    csvName,
    showDownload,
    loading,
    columns,
    dataSource,
    pageSize,
    components,
    bordered,
    showSearch,
    ...restProps
  } = props;

  const [filterTable, setFilterTable] = useState<any>(null);

  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableColumns = columns.map((column: any) => column.title); // Get the column headers
    const tableData = dataSource.map((row: any) =>
      columns.map((column: any) => row[column.dataIndex])
    ); // Get the table data

    // Draw the table
    autoTable(doc, {
      head: [tableColumns],
      body: tableData,
    });

    // Save the PDF
    doc.save((csvName || 'Table') + '.pdf');
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Split the search string into individual words based on spaces.
    const searchTerms: string[] = e.target.value
      .split(' ')
      .map((word) => word.toLowerCase().trim());

    // Get the keys from the columns.
    const keys: string[] = columns.map((item: any) => item.dataIndex);

    // Filter the data source based on the search terms.
    const result = dataSource.filter((item: any) => {
      // For each item, we'll check every search term.
      return searchTerms.every((term) => {
        // If the term is an empty string (which happens if the user enters space), we'll ignore this term.
        if (!term) return true;

        // Check if any key value contains the search term.
        return keys.some((key: string) => {
          return item[key] && String(item[key]).toLowerCase().includes(term);
        });
      });
    });

    // Update the filtered table data.
    setFilterTable(result);
  };

  const headers = columns.map((column: any) => ({
    label: column.label || column.title,
    key: column.key || '',
  }));

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (!sorter || !sorter.column) {
      return;
    }

    const { columnKey, order } = sorter;
    const sortedData = [...(filterTable || dataSource)].sort((a, b) => {
      if (!order) {
        return 0;
      }

      const valueA =
        typeof a[columnKey] === 'number'
          ? a[columnKey]
          : String(a[columnKey]).toLowerCase();
      const valueB =
        typeof b[columnKey] === 'number'
          ? b[columnKey]
          : String(b[columnKey]).toLowerCase();

      if (order === 'ascend') {
        return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
      } else {
        return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
      }
    });

    setFilterTable(sortedData);
  };

  return (
    <>
      {(showSearch || showDownload) && (
        <div
          style={{
            marginTop: 16,
            marginBottom: 16,
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginRight: 20,
            paddingRight: 20,
          }}
        >
          {showSearch && (
            <Search
              style={{
                border: 'secondary',
                height: 28,
                width: 200,
                marginRight: showDownload ? 16 : '',
              }}
              placeholder="Search to Filter"
              // suffix={<SearchOutlined />}
              type="search"
              allowClear
              onChange={onChange}
              disabled={dataSource.length < 1}
            />
          )}
          {showDownload && (
            <div
              style={{
                height: 32,
                paddingRight: 10,
                display: 'flex',
                width: 100,
                marginRight: showDownload ? 16 : '',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Tooltip title="Download PDF">
                  <Button
                    onClick={downloadPDF}
                    color="primary"
                    size={'middle'}
                    shape={'round'}
                  >
                    <FilePdfOutlined />
                  </Button>
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Download CSV">
                  <Button color="secondary" size={'middle'} shape={'round'}>
                    <CSVLink
                      filename={(csvName || 'Table') + '.csv'}
                      data={filterTable || dataSource}
                      headers={headers}
                      // className="secondary"
                    >
                      <FileExcelOutlined />
                    </CSVLink>
                  </Button>{' '}
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      )}
      <Table
        scroll={{ x: 736 }}
        size={'medium'}
        style={{ width: '100%', paddingTop: '3px' }}
        onChange={handleTableChange}
        components={components}
        bordered={bordered}
        loading={loading}
        columns={columns}
        dataSource={filterTable ? filterTable : dataSource}
        pagination={{
          showTotal: (total, range) =>
            `Showing ${range[1]} of ${total} entries`,
          showSizeChanger: true, // Enable option to change the number of entries per page
          pageSizeOptions: ['10', '20', '30', '50', '100'], // Specify available page sizes
          defaultPageSize: 10, // Set the default number of entries per page
        }}
        {...restProps}
      />
    </>
  );
};

export default TableComponent;
