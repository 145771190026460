export const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      // You can also add a render method if you want to format the amount, for example
      render: (text: any) => `Ksh. ${text}`,
    },
    {
      title: 'Payment Date',
      dataIndex: 'payment_date',
      key: 'payment_date',
      // Optional: Use a custom render function if you want to format the date
      render: (text: string | number | Date) => new Date(text).toLocaleDateString(),
    },
    // {
    //   title: 'Is Paid',
    //   dataIndex: 'is_paid',
    //   key: 'is_paid',
    //   // Convert boolean to a user-friendly format
    //   render: (isPaid: any) => (isPaid ? 'Yes' : 'No'),
    // },
    // {
    //   title: 'Client ID',
    //   dataIndex: 'client',
    //   key: 'client',
    // },

  ];
  