export const Columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Client Name',
    dataIndex: ['client', 'name'],
    key: 'clientName',
  },
  {
    title: 'Client Email',
    dataIndex: ['client', 'email'],
    key: 'clientEmail',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Due Amount',
    dataIndex: 'due_amount',
    key: 'dueAmount',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Due Date',
    dataIndex: 'due_date',
    key: 'dueDate',
  },
  {
    title: 'Actions',
    dataIndex: 'interest',
    key: 'interest',
  },
];
