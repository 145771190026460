import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Widget from './Widgets';
// import BestSellingProducts from './BestSellingProducts';
import RecentOrders from './RecentOrders';
import Revenue from './Revenue';
// import SalesByLocations from './SalesByLocations';
import Section from './Section';
import StoreVisits from './StoreVisits';
// import TopSellers from './TopSellers';
import SalesForecast from './SalesForecast';

const DashboardEcommerce = () => {
  document.title = 'Dashboard | At Home Capital || Credit Management System';

  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section rightClickBtn={toggleRightColumn} />
                <Row>
                  <Widget />
                </Row>
                <Row>
                  <Col xl={8}>
                    <Revenue />
                  </Col>
                  <Col xl={4}>
                    <SalesForecast />
                  </Col>
                </Row>
                {/* <Row>
                  <BestSellingProducts />
                  <TopSellers />
                </Row> */}
                <Row>
                  <StoreVisits />
                  <RecentOrders />
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
