// Ensure BASE_URL is defined

import { BASE_URL } from 'helpers';

// Updated interface to match your API response structure
export interface DisbursementStats {
  total_expected_profit: number;
  total_disbursements: number;
  fully_paid_disbursements: number;
  incomplete_disbursements: number;
  total_amount_disbursed: number;
  profit_from_fully_repaid_disbursements: number;
}

// Function to fetch disbursement statistics
export async function fetchDisbursementStats(): Promise<DisbursementStats> {
  try {
    const response = await fetch(
      `${BASE_URL}/disbursements/api/disbursement-stats/`
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const jsonResponse = await response.json();
    if (jsonResponse.status !== 'success') {
      throw new Error(
        'Failed to fetch disbursement stats: ' + jsonResponse.message
      );
    }
    return jsonResponse.data; // Return just the data part
  } catch (error) {
    throw error; // Re-throw the error to be handled by the calling component
  }
}
