import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { BASE_URL } from 'helpers';
import { format } from 'date-fns';

interface AccessToken {
  id: number;
  token: string;
  created_at: string;
  expires_in: number;
}

const AccessTokens = () => {
  const [tokens, setTokens] = useState<AccessToken[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(`${BASE_URL}/disbursements/access_tokens/`)
      .then((res) => res.json())
      .then((data) => {
        setTokens(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching access tokens:', error);
        setLoading(false); // Ensure loading state is updated even if fetch fails
      });
  }, []);

  const handleCopyToClipboard = (token: string) => {
    navigator.clipboard
      .writeText(token)
      .then(() => {
        alert('Token copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };
  document.title = 'Access Tokens | APRPMS';

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Access Tokens" pageTitle="Disbursements" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                  <h5 className="card-title mb-0">Access Tokens</h5>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <div>Loading...</div>
                  ) : tokens.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Token</th>
                            <th>Created At</th>
                            <th>Expires In</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tokens.map((token) => (
                            <tr key={token.id}>
                              <td>{token.id}</td>
                              <td>
                                {token.token}{' '}{' '}
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={() =>
                                    handleCopyToClipboard(token.token)
                                  }
                                >
                                  Copy
                                </Button>
                              </td>
                              <td>
                                {format(new Date(token.created_at), 'PPPpp')}
                              </td>
                              <td>{token.expires_in} seconds</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div>No access tokens found.</div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AccessTokens;
