import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import CountUp from 'react-countup';
import DisbursementStatsChart from './charts/DisbursementsChart';
import { BASE_URL } from 'helpers';

const Revenue = () => {
  const [disbursementStats, setDisbursementStats] = useState([]);
  const [loanRepaymentStats, setLoanRepaymentStats] = useState([]);

  useEffect(() => {
    // Fetch Disbursement Stats
    const fetchDisbursementStats = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/disbursements/api/disbursement-monthly-stats/`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch disbursement stats');
        }
        const data = await response.json();
        setDisbursementStats(data.data);
      } catch (error) {
        console.error('Error fetching disbursement stats:', error);
      }
    };

    // Fetch Loan Repayment Stats
    const fetchLoanRepaymentStats = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/loan-management/api/monthly-repayment-stats/`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch loan repayment stats');
        }
        const data = await response.json();
        setLoanRepaymentStats(data.data);
      } catch (error) {
        console.error('Error fetching loan repayment stats:', error);
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchDisbursementStats(), fetchLoanRepaymentStats()]);
    };

    fetchData();
  }, []);

  // Sample dataColors array for the chart
  const dataColors = ['#008FFB', '#00E396', '#FEB019'];

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Revenue</h4>
        </CardHeader>

        <CardHeader className="p-0 border-0 bg-light-subtle">
          <Row className="g-0 text-center">
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    suffix="k"
                    prefix="ksh"
                    start={0}
                    decimals={2}
                    end={22.89}
                    duration={3}
                  />
                </h5>
                <p className="text-muted mb-0">Clients</p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp start={0} end={7585} duration={3} separator="," />
                </h5>
                <p className="text-muted mb-0">Disbursements</p>
              </div>
            </Col>

            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp start={0} end={367} duration={3} />
                </h5>
                <p className="text-muted mb-0">Payments</p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0 border-end-0">
                <h5 className="mb-1 text-success">
                  <CountUp
                    start={0}
                    end={18.92}
                    decimals={2}
                    duration={3}
                    suffix="%"
                  />
                </h5>
                <p className="text-muted mb-0">Payment to Disbursement Ratio</p>
              </div>
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="p-0 pb-2">
          <div className="w-100">
            <div dir="ltr">
              <div className="App">
                <h1>Disbursement Statistics</h1>
                <DisbursementStatsChart
                  dataColors={dataColors}
                  disbursementStats={disbursementStats}
                  loanRepaymentStats={loanRepaymentStats}
                />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Revenue;
