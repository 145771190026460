import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

// Assuming these interfaces are defined somewhere in your project
interface DataStat {
  month_name: string; // Use the full "month year" format for clarity
  count: number;
}

interface ChartProps {
  dataColors: string[];
  disbursementStats: DataStat[];
  loanRepaymentStats: DataStat[];
}

const DisbursementAndLoanRepaymentChart: React.FC<ChartProps> = ({
  dataColors,
  disbursementStats,
  loanRepaymentStats,
}) => {
  // Utilize the provided getChartColorsArray function or define it based on your needs
  const chartColors = dataColors; // This example assumes dataColors is already suitable for use

  const series = [
    {
        name: 'Disbursements',
        type: 'bar', // Use 'bar' for the bar chart
        data: disbursementStats.map(stat => stat.count),
      },
      {
        name: 'Loan Repayments',
        type: 'area', // Use 'area' for the filled line chart
        data: loanRepaymentStats.map(stat => stat.count),
      },
  ];

  const options: ApexOptions = {
    chart: {
      height: 370,
      type: 'line', // This will be overridden by the series type
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'straight',
      dashArray: [0, 0, 8],
      width: [0, 2.2], // Width for bar (0) and line
    },
    fill: {
        type: ['solid', 'gradient'], // 'gradient' for the line chart to create the fill
        gradient: {
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: undefined, // Optional: define if you want to have a gradient to different colors
          opacityFrom: 0.7,
          opacityTo: 0.3,
        },
      },
    markers: {
      size: 0, // Typically, markers are not used on bars, only on lines
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    xaxis: {
      categories: disbursementStats.map(stat => stat.month_name),
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    grid: {
      show: true,
      padding: {
        top: 0,
        right: -2,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: true,
      horizontalAlign: 'center',
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '30%', // Customization for the bar
      },
    },
    colors: chartColors,
    tooltip: {
      shared: true,
      y: [
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return `${y.toFixed(0)} Disbursements`;
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return `${y.toFixed(0)} Repayments`;
            }
            return y;
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="line" // This is the default type but it will be overridden by the series types
        height="370"
      />
    </React.Fragment>
  );
};

export default DisbursementAndLoanRepaymentChart;
